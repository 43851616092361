import { IInstructionsWizardState } from './types';

const initialInstructionsWizardState = (): IInstructionsWizardState => ({
  error: null,
  loading: false,
  drawTable: false,
  selectedOptions: {},
  decisionFlowStep: 0,
  documents: [],
});

export default initialInstructionsWizardState;
