import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDocument, IInstructionsWizardState } from './types';
import initialInstructionsWizardState from './initialState';

const instructionsWizardSlice = createSlice({
  name: 'instructionsWizard',
  initialState: initialInstructionsWizardState(),
  reducers: {
    set_decision_flow_step(
      state: IInstructionsWizardState,
      action: PayloadAction<number>
    ) {
      state.decisionFlowStep = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_documents_data(
      state: IInstructionsWizardState,
      action: PayloadAction<IDocument[]>
    ) {
      state.documents = action.payload;
      state.error = false;
      state.loading = false;
    },
    set_loading(state: IInstructionsWizardState) {
      state.loading = true;
    },
    set_error(state: IInstructionsWizardState, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default instructionsWizardSlice;
