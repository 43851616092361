import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isTokenExpired } from '../../utils/auth';

interface RequireAuthProps {
  children: React.ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const handleStorageChange = () => {
      if (!localStorage.getItem('loginInfo') || isTokenExpired()) {
        window.location.href = '/login';
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  if (!localStorage.getItem('loginInfo') || isTokenExpired()) {
    return <Navigate to='/login' replace state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default RequireAuth;
