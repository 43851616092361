import React, { useEffect, useState, useCallback } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { theme } from 'antd';
import ChoroplethMap from '../../components/ChoroplethMap';
import {
  getCostsPerJurisdiction,
  getFileTypeCounts,
  getJurisdictionCounts,
  getJurisdictionsList,
  getPublicationCounts,
  getTopCompanies,
  getTopInventors,
} from '../../services/insights/insights.service';
import BarChart from '../../components/BarChart';
import PieChart from '../../components/PieChart';

const Insights: React.FC = () => {
  const { token } = theme.useToken();
  const isDarkMode =
    useSelector((state: RootState) => state.global.uitheme) === 'dark';
  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    width: '100%',
    backgroundColor: isDarkMode ? '#0F0F1A' : '#FCFCFC',
    padding: '40px 36px',
    marginBottom: '61px',
    borderRadius: '10px',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    maxWidth: '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-around',
    rowGap: '40px',
    columnGap: '80px',
  };

  const dispatch = useDispatch<AppDispatch>();
  const [jurisdictions, setJurisdictions] = useState<any[]>([]);
  const [jurisdictionCounts, setJurisdictionCounts] = useState<any[]>([]);
  const [fileTypes, setFileTypes] = useState<any[]>([]);
  const [publications, setPublications] = useState<any[]>([]);
  const [topCompanies, setTopCompanies] = useState<any[]>([]);
  const [topInventors, setTopInventors] = useState<any[]>([]);
  const [costsPerJurisdiction, setCostsPerJurisdiction] = useState<any[]>([]);

  const [loadingJurisdictions, setLoadingJurisdictions] = useState(true);
  const [loadingJurisdictionCounts, setLoadingJurisdictionCounts] =
    useState(true);
  const [loadingFileTypes, setLoadingFileTypes] = useState(true);
  const [loadingPublications, setLoadingPublications] = useState(true);
  const [loadingTopCompanies, setLoadingTopCompanies] = useState(true);
  const [loadingTopInventors, setLoadingTopInventors] = useState(true);
  const [loadingCostsPerJurisdiction, setLoadingCostsPerJurisdiction] =
    useState(true);

  const { searchTerm } =
    useSelector((state: RootState) => state.iProperty) || {};

  console.log('searchTerm', searchTerm);
  const sortPublications = (data: any[]) => {
    return data.sort((a, b) => {
      const yearA = a.groupName ? parseInt(a.groupName) : 0;
      const yearB = b.groupName ? parseInt(b.groupName) : 0;
      return yearA - yearB;
    });
  };

  const fetchData = useCallback(async () => {
    const queryParams = new URLSearchParams();
    queryParams.append('search', searchTerm);
    const queryString = queryParams.toString();

    try {
      setLoadingJurisdictions(true);
      setLoadingJurisdictionCounts(true);
      setLoadingFileTypes(true);
      setLoadingPublications(true);
      setLoadingTopCompanies(true);
      setLoadingTopInventors(true);
      setLoadingCostsPerJurisdiction(true);

      const [
        fetchedJurisdictions,
        fetchedJurisdictionCounts,
        fetchedFileTypes,
        fetchedPublications,
        fetchedTopCompanies,
        fetchedTopInventors,
        fetchedCostsPerJurisdiction,
      ] = await Promise.all([
        getJurisdictionsList(queryString),
        getJurisdictionCounts(queryString),
        getFileTypeCounts(queryString),
        getPublicationCounts(queryString),
        getTopCompanies(queryString),
        getTopInventors(queryString),
        getCostsPerJurisdiction(queryString),
      ]);

      const sortedPublications = sortPublications(fetchedPublications);

      setJurisdictions(fetchedJurisdictions);
      setJurisdictionCounts(fetchedJurisdictionCounts);
      setFileTypes(fetchedFileTypes);
      setPublications(sortedPublications);
      setTopCompanies(fetchedTopCompanies);
      setTopInventors(fetchedTopInventors);
      setCostsPerJurisdiction(fetchedCostsPerJurisdiction);
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description:
          error.response?.data?.message || error.message || error.toString(),
      });
    } finally {
      setLoadingJurisdictions(false);
      setLoadingJurisdictionCounts(false);
      setLoadingFileTypes(false);
      setLoadingPublications(false);
      setLoadingTopCompanies(false);
      setLoadingTopInventors(false);
      setLoadingCostsPerJurisdiction(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (
      jurisdictions.length === 0 ||
      jurisdictionCounts.length === 0 ||
      fileTypes.length === 0 ||
      publications.length === 0 ||
      topCompanies.length === 0 ||
      topInventors.length === 0 ||
      costsPerJurisdiction.length === 0
    ) {
      fetchData();
    }
  }, [
    fetchData,
    jurisdictions.length,
    jurisdictionCounts.length,
    fileTypes.length,
    publications.length,
    topCompanies.length,
    topInventors.length,
    costsPerJurisdiction.length,
  ]);

  return (
    <div style={contentStyle}>
      <ChoroplethMap data={jurisdictions} />
      <BarChart
        title='Jurisdiction'
        data={jurisdictionCounts}
        isApplications={false}
        isVertical={true}
        iscomp={false}
        isInv={false}
        loading={loadingJurisdictionCounts}
      />
      <BarChart
        title='Applications over time'
        data={publications}
        isApplications={true}
        isVertical={true}
        iscomp={false}
        isInv={false}
        loading={loadingPublications}
      />
      <PieChart
        data={fileTypes}
        isCosts={false}
        title='IP assets'
        loading={loadingFileTypes}
      />
      <PieChart
        data={costsPerJurisdiction}
        isCosts={true}
        title='Costs per jurisdiction'
        loading={loadingCostsPerJurisdiction}
      />
      <BarChart
        title='IP assets per company'
        data={topCompanies}
        isApplications={false}
        isVertical={false} // Set to horizontal
        iscomp={true}
        isInv={false}
        loading={loadingTopCompanies}
      />
      <BarChart
        title='IP assets per inventor'
        data={topInventors}
        isApplications={false}
        isVertical={false} // Set to horizontal
        iscomp={false}
        isInv={true}
        loading={loadingTopInventors}
      />
    </div>
  );
};

export default Insights;
