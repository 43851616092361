import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialBusinessState } from './initialState';
import { IBusiness, IBusinessState } from './types';

const BusinessSlice = createSlice({
  name: 'business',
  initialState: initialBusinessState(),
  reducers: {
    search_business: (state: IBusinessState, action: PayloadAction<string>) => {
      const searchTerm = action.payload.toLowerCase();
      state.searchResults.results = state.data.filter((item) => {
        const searchableFields = [
          item.fileType,
          item.product || '',
          ...(item.companyDetails?.map((detail) => detail.name) || []),
          ...(item.companyDetails?.map((detail) => detail.addressLine1) || []),
          ...(item.companyDetails?.map((detail) => detail.postalCode) || []),
          ...(item.companyDetails?.map((detail) => detail.city) || []),
          ...(item.companyDetails?.map((detail) => detail.country) || []),
          ...(item.companyDetails?.map((detail) =>
            detail.VATnumber?.toString()
          ) || []),
          ...(item.companyDetails?.map((detail) =>
            detail.VATpercentage?.toString()
          ) || []),
        ];

        return searchableFields.some(
          (field) => field && field.toLowerCase().includes(searchTerm)
        );
      });
    },
    set_business_data: (
      state: IBusinessState,
      action: PayloadAction<IBusiness[]>
    ) => {
      state.data = [...action.payload];
      state.searchResults.results = [];
    },
    set_selected_property_data(
      state: IBusinessState,
      action: PayloadAction<IBusiness | null>
    ) {
      state.selectedPropertyData = action.payload;
    },
    set_loading: (state, action) => {
      state.loading = action.payload;
    },
    set_error: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default BusinessSlice;
