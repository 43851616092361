import React, { useState, useEffect } from 'react';
import { Button, Typography, Form, Input, Tag } from 'antd';
import { fetchInvoicePreview } from '../../services/tasksDataService/invoice.service';
import { Wrapper } from './InvoicingStep.styled';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { updateCompanyAddress } from '../../services/companies/companies.service';
import { fetchUserData } from '../../services/userDataService/user.service';
import { setUserData } from '../../store/ducks/Global/operations';
import {
  EditTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  PlusCircleTwoTone,
  PlusCircleFilled,
} from '@ant-design/icons';
import { CompanyDetails } from '../../store/ducks/Global/types';

const { Text, Title } = Typography;

const InvoicingStep: React.FC<{
  totalAmount: number;
  taxableAmount: number;
}> = ({ totalAmount, taxableAmount }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();

  const { userData } = useSelector((state: RootState) => state.global);
  const { companyDetails } = userData;
  const [addressData, setAddressData] =
    useState<CompanyDetails>(companyDetails);
  const [htmlContent, setHtmlContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [reference, setReference] = useState<string[]>(
    companyDetails.reference || []
  );
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    setAddressData(companyDetails);
  }, [companyDetails, dispatch]);

  useEffect(() => {
    fetchInvoicePreview(totalAmount, taxableAmount)
      .then((html) => setHtmlContent(html))
      .catch((error) =>
        console.error('Error fetching invoice preview:', error)
      );
  }, [totalAmount, taxableAmount]);

  const toggleEditMode = () => setIsEditing((prevIsEditing) => !prevIsEditing);

  const onFinish = async (values: any) => {
    try {
      const updatedValues = { ...values, id: addressData.id, reference };
      setAddressData(updatedValues);
      setIsEditing(false);
      await updateCompanyAddress(updatedValues);
      const updatedUserData = await fetchUserData();
      const updatedHtml = await fetchInvoicePreview(totalAmount, taxableAmount);
      setHtmlContent(updatedHtml);
      dispatch(setUserData(updatedUserData));
    } catch (error) {
      console.error('Error updating address and fetching user data:', error);
    }
  };

  const onCancel = () => {
    setIsEditing(false);
    form.resetFields();
    setReference(companyDetails.reference || []);
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue && (inputValue.endsWith(',') || inputValue.endsWith(';'))) {
      const newReference = [...reference, inputValue.slice(0, -1)];
      setReference(newReference);
      setShowInput(false);
    }
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newReference = [...reference, e.currentTarget.value.trim()];
      setReference(newReference);
      setShowInput(false);
    }
  };

  return (
    <Wrapper>
      <Title level={4} className='h3'>
        Address Information
      </Title>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        initialValues={addressData}
        style={{ maxWidth: '760px' }}
      >
        <Form.Item noStyle>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!isEditing && (
              <Button
                type='text'
                icon={<EditTwoTone style={{ fontSize: '22px' }} />}
                onClick={toggleEditMode}
              />
            )}
            {isEditing && (
              <>
                <Button
                  type='text'
                  icon={<CloseCircleTwoTone style={{ fontSize: '22px' }} />}
                  onClick={onCancel}
                  style={{ marginRight: '10px' }}
                />
                <Button
                  type='primary'
                  icon={<CheckCircleTwoTone style={{ fontSize: '22px' }} />}
                  htmlType='submit'
                />
              </>
            )}
          </div>
        </Form.Item>
        <Form.Item
          label='Company Name:'
          name='companyName'
          rules={[{ required: true }]}
        >
          <Input placeholder='Company Name' disabled={!isEditing} />
        </Form.Item>
        <Form.Item
          label='Street Address:'
          name='addressLine1'
          rules={[{ required: true }]}
        >
          <Input placeholder='Street Address' disabled={!isEditing} />
        </Form.Item>
        <Form.Item
          label='ZIP/Postal Code:'
          name='postalCode'
          rules={[{ required: true }]}
        >
          <Input placeholder='ZIP/Postal Code' disabled={!isEditing} />
        </Form.Item>
        <Form.Item label='City:' name='city' rules={[{ required: true }]}>
          <Input placeholder='City' disabled={!isEditing} />
        </Form.Item>
        <Form.Item label='Country:' name='country'>
          <Input placeholder='Country' disabled />
        </Form.Item>
        <Form.Item label='Reference:' name='reference'>
          {/* Tags */}
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              marginRight: '10px',
              ...(reference.length !== 0 && { marginBottom: 20 }),
            }}
          >
            {reference.map((email, index) => (
              <Tag
                key={index}
                closable={isEditing}
                color='processing'
                onClose={() => {
                  const newReference = [...reference];
                  newReference.splice(index, 1);
                  setReference(newReference);
                }}
                style={{
                  marginRight: '10px',
                }}
              >
                {email}
              </Tag>
            ))}
            {!showInput && (
              <Button
                type='text'
                disabled={!isEditing}
                icon={
                  !isEditing ? (
                    <PlusCircleFilled style={{ fontSize: '22px' }} />
                  ) : (
                    <PlusCircleTwoTone style={{ fontSize: '22px' }} />
                  )
                }
                onClick={() => setShowInput(true)}
                style={{ marginRight: '10px' }}
              />
            )}
          </div>
          {showInput && (
            <Input
              autoFocus
              placeholder='Enter email addresses'
              onBlur={() => setShowInput(false)}
              onKeyDown={handleEnterPress}
              onChange={handleInput}
              style={{ flex: 1 }}
            />
          )}
        </Form.Item>
      </Form>
      <div
        style={{ marginTop: '50px', width: 'fit-content', textAlign: 'left' }}
      >
        <Title level={4} className='h3'>
          Invoice Preview
        </Title>
        <div
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          style={{ width: '100%', wordWrap: 'break-word', marginTop: '-20px' }}
        />
      </div>
    </Wrapper>
  );
};

export default InvoicingStep;
