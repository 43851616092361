import { Breadcrumb } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled('div')`
  .h3 {
    color: gray;
    width: fit-content;
    margin-top: 0;
  }
`;
