import { Button, Form } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled('div')`
  display: flex;
  flex-direction: row;
  // min-height: calc(
  //   100vh - 50px
  // ); /* Adjust to ensure it doesn't exceed viewport height */
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 20px; /* Add padding to prevent content from touching the edges */
  box-sizing: border-box; /* Ensure padding is included in height */
  gap: 35px;
  margin-top: 50px; /* Adjust this value to keep some space from the top */
`;

export const Footer = styled('footer')`
  width: 100%;
  position: fixed; /* Keep the footer at the bottom of the viewport */
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const BottomImage = styled('img')`
  width: 100%;
  height: auto;
`;

export const FormContainer = styled('div')`
  width: 450px;
  display: flex;
  flex-direction: column;
  align-self: flex-start; /* Center the form */
`;

export const StyledFormItem = styled(Form.Item)`
  .ant-input,
  .ant-input-affix-wrapper {
    padding: 16px 14px;
  }

  .ant-input-prefix,
  .ant-input-suffix,
  .ant-input-suffix span {
    font-size: 20px;
    color: #757474 !important;
  }

  .ant-col {
    min-width: 100%;
  }
`;

export const ItemsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ant-row.ant-form-item-row {
    min-width: 100%;
  }

  .ant-form-item .ant-form-item-label {
    text-align: initial;
  }
`;

export const BottomSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  > button {
    align-self: center;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledLinkButton = styled(Button)`
  > span {
    text-decoration: underline;
    color: black;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      color: black;
    }
  }
`;

export const StyledLinkButtonDanger = styled(Button)`
  margin-bottom: 15px;
`;

export const StyledDisabledButton = styled(Button)`
  margin-bottom: 15px;
  min-width: 205px;
  border-radius: 15px;
`;

export const OrText = styled.div`
  font-size: 16px;
  color: #999999;
  margin: 0 10px;
`;

export const TopSection = styled('div')`
  align-self: flex-start;
`;

export const LogoImage = styled('img')`
  width: 212px;
  height: auto;
`;

export const LinkContainer = styled('div')`
  margin-top: 80px;
  font-size: 15px;
  font-weight: 200;

  a {
    color: #1890ff;
    cursor: pointer;

    &:hover {
      color: #40a9ff;
    }
  }
`;
