import { Breadcrumb } from 'antd';
import styled from 'styled-components';

export const StyledBreadcrumbItem = styled(Breadcrumb.Item)`
  font-weight: 700;
  cursor: pointer;
  margin-right: 10px;
  button {
    margin-bottom: 20px;
  }
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  color: '#24466a';

  ol {
    justify-content: end;
  }
`;

export const StyledDocItem = styled('div')`
  text-align: start;
  p {
    margin: 5px 0;
  }
`;

export const StyledDocItemAdditInfo = styled('div')`
  text-align: end;
  height: fit-content;
  margin-top: 16.5px;
  color: #24466a;
  p {
    margin: 5px 0;
  }
`;

export const TotalAmountWrapper = styled('div')`
  font-weight: bold;
  text-align: end;
  margin-right: 50px;
  font-size: medium;
  color: rgb(36, 70, 106);
`;
