import React, { useEffect, useState } from 'react';
import { notification, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { setPageInfo } from '../../store/ducks/Global/operations';
import { getBatchCostsData } from '../../services/costsService/costsService.service';
import GroupedCards from '../../components/GroupedCards';
import { theme } from 'antd';

interface Financial {
  guid: string;
  fileOwnerID: string[];
  publisherID: string;
  fileType: string;
  invoiceDate: string;
  invoiceNumber: number;
  description: string;
  type: string;
  amount: number;
  costDate: string;
}

const Financial: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { token } = theme.useToken();
  const [costsData, setCostsData] = useState<Financial[]>([]);
  const [loading, setLoading] = useState(true);
  const isDarkMode =
    useSelector((state: RootState) => state.global.uitheme) === 'dark';

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    width: '65%',
    minWidth: 'fit-content',
    backgroundColor: isDarkMode ? '#0F0F1A' : '#FCFCFC',
    padding: '40px 36px',
    marginBottom: '61px',
    borderRadius: '10px',
    display: 'grid',
    gap: '26px',
    height: '100%',
  };

  useEffect(() => {
    const fetchCosts = async () => {
      try {
        const data = await getBatchCostsData();
        const sortedData = data.sort(
          (a: Financial, b: Financial) =>
            new Date(b.costDate).getTime() - new Date(a.costDate).getTime()
        );
        setCostsData(sortedData);
      } catch (error: any) {
        notification.error({
          message: 'Request failed',
          description: 'Failed to get financial data',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCosts();
  }, []);

  const groupDataByYear = (data: Financial[]) => {
    return data.reduce((acc: Record<string, Financial[]>, item) => {
      const year = new Date(item.costDate).getFullYear().toString();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(item);
      return acc;
    }, {});
  };

  const groupedData = groupDataByYear(costsData);

  useEffect(() => {
    dispatch(setPageInfo({ currentPageName: 'Financial' }));
  }, [dispatch]);

  return (
    <div style={contentStyle}>
      {loading ? (
        <Spin tip='Loading...' size='large' style={{ margin: 'auto' }} />
      ) : (
        <GroupedCards groupedData={groupedData} isDarkMode={isDarkMode} />
      )}
    </div>
  );
};

export default Financial;
