import React, { useEffect, useState } from 'react';
import { theme, notification, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { setPageInfo } from '../../store/ducks/Global/operations';
import { setFileData } from '../../store/ducks/File/operations';
import GroupedCards from '../../components/GroupedCards';
import {
  getAllFilesData,
  markAllAsRead,
  markAsRead,
} from '../../services/filesService/filesService.service';

interface Files {
  id: string;
  guid: string;
  publisherId: string;
  ownerId: string[];
  nodeId: string[];
  fileJSON: string;
  filepath: string;
  date: number;
  isRead: boolean;
  filePresignedUrl: string;
}

const FileComponent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { token } = theme.useToken();
  const isDarkMode =
    useSelector((state: RootState) => state.global.uitheme) === 'dark';
  const [filesData, setFilesData] = useState<Files[]>([]);
  const [loading, setLoading] = useState(true);
  const [previewOpen, setPreviewOpen] = useState(false);

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    width: '65%',
    minWidth: 'fit-content',
    backgroundColor: isDarkMode ? '#0F0F1A' : '#FCFCFC',
    padding: '40px 36px',
    marginBottom: '61px',
    borderRadius: '10px',
    display: 'grid',
    gap: '26px',
    height: '100%',
  };

  useEffect(() => {
    dispatch(
      setPageInfo({ currentPageName: 'Files', numberOfElem: filesData.length })
    );
  }, [dispatch, filesData.length]);

  useEffect(() => {
    dispatch(setFileData(filesData));
  }, [dispatch, filesData]);

  const clickFile = () => {
    document.getElementById('filePreview')!.innerText =
      "This file select cannot be viewed because you don't have access please contact support to give access";
    setPreviewOpen(true);
  };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const data = await getAllFilesData();
        const sortedData = data.sort((a: Files, b: Files) => b.date - a.date);
        setFilesData(sortedData);
      } catch (error: any) {
        notification.error({
          message: 'Request failed',
          description: 'Failed to get files data',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  const groupDataByYear = (data: Files[]) => {
    return data.reduce((acc: Record<string, Files[]>, item) => {
      const year = new Date(item.date).getFullYear().toString();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(item);
      return acc;
    }, {});
  };

  const onMarkAllAsRead = async () => {
    setLoading(true); // Start loading
    try {
      await markAllAsRead();
      const updatedData = await getAllFilesData(); // Fetch the latest data
      setFilesData(updatedData); // Update files data with fresh data from the server
      notification.success({
        message: 'Success',
        description: 'All files marked as read.',
      });
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description: 'Failed to mark all files as read',
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const onMarkAsRead = async (guid: string) => {
    setLoading(true); // Optional: show loading indicator for consistency
    try {
      await markAsRead(guid);
      const updatedData = await getAllFilesData(); // Refetch data from the server
      setFilesData(updatedData); // Update state with the latest data
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description: 'Failed to mark file as read',
      });
    } finally {
      setLoading(false); // Stop loading indicator if added
    }
  };

  const groupedData = groupDataByYear(filesData);

  return (
    <div style={contentStyle}>
      {loading ? (
        <Spin tip='Loading...' size='large' style={{ margin: 'auto' }} />
      ) : (
        <GroupedCards
          groupedData={groupedData}
          isDarkMode={isDarkMode}
          isFiles={true}
          onMarkAllAsRead={onMarkAllAsRead}
          onMarkAsRead={onMarkAsRead}
        />
      )}
    </div>
  );
};

export default FileComponent;
