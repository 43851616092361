import styled from 'styled-components';
import { Breadcrumb, Button, Layout } from 'antd';
import lightBackground from '../assets/light-bg.jpg';
import darkBackground from '../assets/dark-bg.jpg';

export interface StyledProps {
  collapsed?: boolean;
  isDarkMode?: boolean;
}

export const StyledLayout = styled(Layout)<StyledProps>`
  width: auto !important;
  position: relative;
  z-index: 1;
  transition: background-color 0.3s, color 0.3s;
  transition: background 0.3s, color 0.3s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  &::before {
    background-image: ${({ isDarkMode }) =>
      isDarkMode ? `url(${darkBackground})` : `url(${lightBackground})`};
  }
`;

export const StyledBreadcrumbItem = styled(Breadcrumb.Item)`
  color: white;
  font-weight: 900;
  font-size: 16px;
`;

export const StyledButton = styled(Button)`
  width: 38px !important;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
`;
