import IPropertySlice from './BusinessSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBusiness } from './types';

const {
  set_business_data,
  search_business,
  set_selected_property_data,
  set_loading,
  set_error,
} = IPropertySlice.actions;

export const searchBusinessData = createAsyncThunk(
  'searchBusinessData',
  async (searchTerm: string, { dispatch }) => {
    try {
      dispatch(set_loading(true));
      dispatch(search_business(searchTerm));
      dispatch(set_loading(false));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export const setBusinessData = createAsyncThunk(
  'setBusinessData',
  async (data: IBusiness[], { dispatch }) => {
    try {
      dispatch(set_loading(true));
      dispatch(set_business_data(data));
      dispatch(set_loading(false));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export const setSelectedPropertyData = createAsyncThunk(
  'setSelectedPropertyData',
  async (data: IBusiness | null, { dispatch }) => {
    try {
      dispatch(set_selected_property_data(data));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export default {
  searchBusinessData,
  setBusinessData,
  setSelectedPropertyData,
};
