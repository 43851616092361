import { Select } from 'antd';
import styled from 'styled-components';

interface StyledProps {
  isDarkMode?: boolean;
}

export const StyledDocItem = styled('div')`
  text-align: start;
  p {
    margin: 5px 0;
  }
`;

export const StyledSelect = styled(Select)<StyledProps>`
  .ant-select-selector,
  ant-select-selection-search,
  .ant-select-selector::after {
    // width: 100px;
    border-radius: 29px !important;
    border-color: #cacaca;
    padding: 10px 17px;
    background: ${({ isDarkMode }) => isDarkMode && '#0f0f1a'} !important;
    border: ${({ isDarkMode }) => isDarkMode && '1px solid #656A92'} !important;
    color: ${({ isDarkMode }) => isDarkMode && '#FFFFFF'} !important;
    padding: inherit;
    // &:hover,
    // :focus {
    //   border: 1px solid red !important;
    // }
  }

  .ant-select-selection-item {
    color: ${({ isDarkMode }) => isDarkMode && '#FFFFFF'} !important;
  }

  .ant-select-arrow {
    color: ${({ isDarkMode }) => isDarkMode && '#FFFFFF'} !important;
  }
`;
