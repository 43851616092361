export const isTokenExpired = (): boolean => {
  const userInfoString = localStorage.getItem('loginInfo');
  if (!userInfoString) return true;

  const loginInfo = JSON.parse(userInfoString);
  const token = loginInfo.accessToken;
  if (!token) return true;

  const payloadBase64 = token.split('.')[1];
  const decodedPayload: { exp: number } = JSON.parse(atob(payloadBase64));
  const currentTime = Date.now() / 1000;
  console.log('expire time', decodedPayload.exp);
  return decodedPayload.exp < currentTime;
};
