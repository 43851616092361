import { FileSearchState, FileState } from './types';

export const initialFileState = (): FileState  => ({
    data:[],
    searchResults: initialSearchState,
    error: null,
    loading: false,
    selectedPropertyData: null
});

export const initialSearchState: FileSearchState = {
    results: []
}