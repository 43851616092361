import IPropertySlice from './IPropertySlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ImageURLs, IPropertyCard, IPropertySingleCard } from './types';

const {
  set_iProperty_data,
  set_image_urls,
  set_filtered_data,
  search_iProperty,
  set_current_page,
  set_items_per_page,
  set_search_term,
  set_selected_property_data,
  set_loading,
  set_error,
} = IPropertySlice.actions;

export const searchIPropertyData = createAsyncThunk(
  'searchIProperty',
  async (searchTerm: string, { dispatch }) => {
    try {
      dispatch(set_loading(true));
      dispatch(search_iProperty(searchTerm));
      dispatch(set_loading(false));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export const setSearchTerm = createAsyncThunk(
  'iProperty/setSearchTerm',
  async (term: string, { dispatch }) => {
    dispatch(set_search_term(term));
  }
);

export const setCurrentPage = createAsyncThunk(
  'iProperty/setCurrentPage',
  async (page: number, { dispatch }) => {
    dispatch(set_current_page(page));
  }
);

export const setItemsPerPage = createAsyncThunk(
  'iProperty/setItemsPerPage',
  async (items: number, { dispatch }) => {
    dispatch(set_items_per_page(items));
  }
);

export const setIPCardsData = createAsyncThunk(
  'setIPCardsData',
  async (data: IPropertyCard[], { dispatch }) => {
    try {
      dispatch(set_loading(true));
      dispatch(set_iProperty_data(data));
      dispatch(set_loading(false));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export const setImageURLs = createAsyncThunk(
  'iProperty/setImageURLs',
  async (urls: ImageURLs, { dispatch }) => {
    try {
      dispatch(set_image_urls(urls)); // Dispatch the reducer to update the state
    } catch (error: any) {
      // Handle error if any logic is added later
      console.error('Failed to set image URLs:', error.message);
    }
  }
);

export const setFilteredIPCardsData = createAsyncThunk(
  'setFilteredIPCardsData',
  async (data: IPropertyCard[], { dispatch }) => {
    try {
      dispatch(set_loading(true));
      dispatch(set_filtered_data(data));
      dispatch(set_loading(false));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export const setSelectedPropertyData = createAsyncThunk(
  'setSelectedPropertyData',
  async (data: IPropertySingleCard, { dispatch }) => {
    try {
      dispatch(set_selected_property_data(data));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export default {
  searchIPropertyData,
  setIPCardsData,
  setSelectedPropertyData,
};
