import React, { useContext, useEffect, useState } from 'react';
import { Input, Form, notification, Spin, Divider } from 'antd';
import {
  UserOutlined,
  WindowsFilled,
  GoogleOutlined,
  LinkedinFilled,
} from '@ant-design/icons';
import { login } from '../../services/authenticationService/auth.service';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BottomSection,
  FormContainer,
  ItemsContainer,
  StyledButton,
  StyledContainer,
  StyledFormItem,
  OrText,
  LogoImage,
  TopSection,
  BottomImage,
  Footer,
  LinkContainer,
} from './Login.styled';
import {
  initiateAzureADAuthentication,
  processAzureADResponse,
} from '../../services/authenticationService/azureAuthService';
import {
  initiateGoogleAuthentication,
  processGoogleResponse,
} from '../../services/authenticationService/googleAuthService';
import {
  initiateLinkedinAuthentication,
  processLinkedinResponse,
} from '../../services/authenticationService/linkedinAuthService';
import { isTokenExpired } from '../../utils/auth';
import arnoldSlogo from '../../assets/arnoldSlogo.png';
import arnoldSfooter from '../../assets/arnoldSfooter.png';
import { EXTERNAL_LINKS } from '../../utils/constants';

const LoginForm: React.FC = () => {
  const [form] = Form.useForm();
  const currentUrl = window.location.hostname;
  const isArnoldSiedsma = currentUrl.includes('arnold-siedsma');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const getUrlParameter = (name: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const parameterValue = urlSearchParams.get(name);
    return parameterValue === null ? '' : decodeURIComponent(parameterValue);
  };
  const [loading, setLoading] = useState(false);
  const emailParameter = getUrlParameter('email');
  const [emailValue, setEmailValue] = useState(emailParameter || '');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ssoAuthInProgress, setSsoAuthInProgress] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTokenExpired()) {
        localStorage.removeItem('loginInfo');
        setIsAuthenticated(false);
        navigate('/login');
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [navigate]);

  useEffect(() => {
    const loginInfo = localStorage.getItem('loginInfo');
    if (loginInfo && !isTokenExpired()) {
      navigate('/dashboard');
    }
  }, [navigate]);

  useEffect(() => {
    if (emailParameter) {
      const emailInput = document.getElementById(
        'email-input'
      ) as HTMLInputElement;
      if (emailInput) {
        emailInput.value = emailParameter;
      }
    }
  }, []);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const loginInfo = await login(values.email, values.password);
      setIsAuthenticated(true);
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || error.message || error.toString();
      notification.error({
        message: 'Login failed',
        description: errorMessage,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(event.target.value);
  };

  const processOAuthResponse = async (
    authMethod: string | null,
    codeParam: string
  ) => {
    // sessionStorage.removeItem('authMethod');

    try {
      if (authMethod === 'google') {
        await processGoogleResponse(codeParam);
      } else if (authMethod === 'azure') {
        await processAzureADResponse(codeParam);
      } else if (authMethod === 'linkedin') {
        await processLinkedinResponse(codeParam);
      }
      setIsAuthenticated(true);
      navigate('/dashboard');
    } catch (error: any) {
      console.error(`Error during ${authMethod} sign in: `, error);
      notification.error({
        message: `${authMethod} Sign In Error`,
        description: error.message || 'Could not sign in with your account.',
      });
      navigate('/login');
    } finally {
      setSsoAuthInProgress(false);
    }
  };

  useEffect(() => {
    const authMethod = sessionStorage.getItem('authMethod');
    const codeParam = new URLSearchParams(location.search).get('code');

    if (authMethod && codeParam) {
      setSsoAuthInProgress(true);
      processOAuthResponse(authMethod, codeParam);
    }
  }, [location]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleAzureADSignIn = async () => {
    sessionStorage.setItem('authMethod', 'azure');
    await initiateAzureADAuthentication();
  };

  const handleGoogleSignIn = async () => {
    sessionStorage.setItem('authMethod', 'google');
    await initiateGoogleAuthentication();
  };

  const handleLInkedinSignIn = async () => {
    sessionStorage.setItem('authMethod', 'linkedin');
    await initiateLinkedinAuthentication();
  };

  if (ssoAuthInProgress) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin size='large' />
      </div>
    );
  }

  return (
    <>
      <StyledContainer>
        {isArnoldSiedsma && (
          <>
            <TopSection>
              <LogoImage src={arnoldSlogo} alt='Company Logo' />
            </TopSection>
            <Divider
              type='vertical'
              style={{
                height: '450px',
                borderColor: '#f0f0f0',
                marginBottom: 'auto',
              }}
            />
          </>
        )}

        <FormContainer>
          <Form form={form} name='login' onFinish={handleSubmit}>
            {!isArnoldSiedsma && (
              <ItemsContainer>
                <StyledFormItem
                  label='Username/Email'
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className='site-form-item-icon' />}
                    type='email'
                    name='email-input'
                    id='email-input'
                    onChange={handleEmailChange}
                  />
                </StyledFormItem>
                <StyledFormItem
                  label='Password'
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </StyledFormItem>
              </ItemsContainer>
            )}

            <BottomSection>
              {!isArnoldSiedsma && (
                <>
                  <StyledButton type='primary' size='large' htmlType='submit'>
                    Sign In
                  </StyledButton>
                  <Divider>
                    <OrText>OR</OrText>
                  </Divider>
                </>
              )}

              <StyledButton
                size='large'
                onClick={handleAzureADSignIn}
                icon={<WindowsFilled style={{ fontSize: '18px' }} />}
              >
                Sign in with Microsoft
              </StyledButton>
              <StyledButton
                size='large'
                onClick={handleGoogleSignIn}
                icon={<GoogleOutlined style={{ fontSize: '18px' }} />}
              >
                Sign in with Google
              </StyledButton>
              <StyledButton
                size='large'
                onClick={handleLInkedinSignIn}
                icon={<LinkedinFilled style={{ fontSize: '18px' }} />}
              >
                Sign in with Linkedin
              </StyledButton>
            </BottomSection>
            {isArnoldSiedsma && (
              <LinkContainer>
                <a
                  href={EXTERNAL_LINKS.AS_TERMS_CONDITIONS}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms & general conditions
                </a>
              </LinkContainer>
            )}
          </Form>
        </FormContainer>
      </StyledContainer>
      {isArnoldSiedsma && (
        <Footer>
          <BottomImage src={arnoldSfooter} alt='Decorative Bottom Image' />
        </Footer>
      )}
    </>
  );
};

export default LoginForm;
