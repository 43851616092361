import React, { useEffect, useState } from 'react';
import { Input, Form, theme } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { login } from '../../services/authenticationService/auth.service';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store/configureStore';
import { useDispatch } from 'react-redux';
import { setPageInfo } from '../../store/ducks/Global/operations';

const { useToken } = theme;

const Orders: React.FC = () => {
  const { token } = useToken();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  
  const getUrlParameter = (name: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const parameterValue = urlSearchParams.get(name);
    return parameterValue === null ? '' : decodeURIComponent(parameterValue);
  };

  useEffect(() => {
    dispatch(setPageInfo({
      "currentPageName": 'Orders',

    }))
  }, [dispatch])

  const emailParameter = getUrlParameter('email');

  const [emailValue, setEmailValue] = useState(emailParameter || '');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(event.target.value);
  };

  return <>Orders page</>;
};

export default Orders;
