import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGlobalState } from './types';
import initialGlobalState from './initialState';

const globalSlice = createSlice({
  name: 'global',
  initialState: initialGlobalState(),
  reducers: {
    set_user_data: (state: IGlobalState, action: PayloadAction<any>) => {
      state.userData = action.payload;
      state.error = false;
      state.loading = false;
    },
    clear_user_data: (state: IGlobalState) => {
      state.userData = {
        _class: 'user',
        id: null,
        userId: '',
        firstName: '',
        lastName: '',
        companyDetails: {
          id: '',
          addressLine1: '',
          postalCode: '',
          city: '',
          country: '',
          companyName: '',
          reference: [],
        },
        email: '',
        publisherId: '',
        publisherName: null,
        companies: [],
        companyIds: [],
      };
    },
    set_error(state: any, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    set_page_info: (state: IGlobalState, action: PayloadAction<any>) => {
      state.currentPageName = action.payload.currentPageName;
      state.numberOfElem = action.payload.numberOfElem;
    },
    set_ui_theme: (state: IGlobalState, action: PayloadAction<any>) => {
      state.uitheme = action.payload;
    },
  },
});

export default globalSlice;
