import React, { useEffect, useState } from 'react';
import type { PaginationProps } from 'antd';
import { theme, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { StyledDocItem } from './Business.styled';
import jsonData from '../../testJson/testfile.json';
import { IBusiness } from '../../store/ducks/Business/types';
import SearchComponent from '../../components/Search';
import {
  searchBusinessData,
  setBusinessData,
} from '../../store/ducks/Business/operations';
import { Card } from 'antd';
import { setPageInfo } from '../../store/ducks/Global/operations';

const Business: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { token } = theme.useToken();
  const { uitheme } = useSelector((state: RootState) => state.global);
  const isDarkMode = uitheme === 'dark';
  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    width: '100%',
    minHeight: '500px',
  };

  useEffect(() => {
    dispatch(
      setPageInfo({
        currentPageName: 'Business',
        numberOfElem: jsonData.data.business.length,
      })
    );
  }, [dispatch]);

  const [currentPage, setCurrentPage] = useState(1);
  const { searchResults } =
    useSelector((state: RootState) => state.business) || {};
  const [searchTerm, setSearchTerm] = useState<string>('');

  const startIndex = (currentPage - 1) * 10;
  const propertiesToDisplay: IBusiness[] =
    searchResults.results.length > 0
      ? searchResults.results
      : jsonData.data.business;

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page);
  };
  const spliceDocument = propertiesToDisplay.slice(startIndex, startIndex + 10);
  useEffect(() => {
    dispatch(setBusinessData(jsonData.data.business));
  }, [dispatch]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);

    if (value.trim() !== '') {
      dispatch(searchBusinessData(value));
    } else {
      dispatch(setBusinessData(jsonData.data.business));
    }
  };

  return (
    <>
      <div
        style={{
          margin: '30px 50px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '30px',
        }}
      >
        {/* <SearchComponent
          onSearch={handleSearch}
          type={'business'}
          isDarkMode={isDarkMode}
        /> */}
        <div style={contentStyle}>
          {searchTerm && !searchResults.results.length ? (
            <p>No results found.</p>
          ) : spliceDocument.length ? (
            spliceDocument.map((property, index) => (
              <div
                key={property.guid}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Card
                  title={property.companyDetails?.[0].name || property.fileType}
                  headStyle={{
                    textAlign: 'left',
                    border: 'none',
                    color: '#24466a',
                  }}
                  bodyStyle={{ padding: '0 12px' }}
                  size='small'
                  style={{
                    width: '70%',
                    marginBottom: '20px',
                    border: 'none',
                    backgroundColor: 'white',
                    background: 'white',
                  }}
                >
                  <StyledDocItem key={property.guid}>
                    <p style={{ color: '#24466a' }}>
                      {`${property.companyDetails?.[0]?.addressLine1 || ''} 
       ${property.companyDetails?.[0]?.postalCode || ''} 
       ${property.companyDetails?.[0]?.city || ''} 
       ${property.companyDetails?.[0]?.country || ''}`}
                    </p>

                    <p style={{ color: 'gray' }}>
                      {`${property.product || ''} `}
                    </p>
                    <p style={{ color: 'gray' }}>
                      {`${property.region || ''} `}
                    </p>
                    {property.companyDetails?.[0]?.hasOwnProperty(
                      'VATnumber'
                    ) && (
                      <p style={{ color: 'gray' }}>
                        {`VAT number: ${
                          (property.companyDetails?.[0] as any).VATnumber
                        }`}
                      </p>
                    )}
                    {property.companyDetails?.[0]?.hasOwnProperty(
                      'VATpercentage'
                    ) && (
                      <p style={{ color: 'gray' }}>
                        {`VAT percentage: ${
                          (property.companyDetails?.[0] as any).VATpercentage
                        }`}
                      </p>
                    )}
                  </StyledDocItem>
                </Card>
              </div>
            ))
          ) : (
            <p>No results found.</p>
          )}
          {propertiesToDisplay.length > 10 ? (
            <Pagination
              defaultCurrent={1}
              total={propertiesToDisplay.length}
              onChange={onChange}
              showSizeChanger={false}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default Business;
