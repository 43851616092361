import { CompanyDetails, IGlobalState } from './types';

const initialGlobalState = (): IGlobalState => ({
  error: null,
  loading: false,
  uitheme: localStorage.getItem('theme') || 'dark',
  userData: {
    _class: 'user',
    id: null,
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    publisherId: '',
    publisherName: null,
    companies: [],
    companyIds: [],
    companyDetails: initialCompanyDetails,
  },
  currentPageName: '',
  numberOfElem: 0,
});

const initialCompanyDetails: CompanyDetails = {
  id: '',
  addressLine1: '',
  postalCode: '',
  city: '',
  country: '',
  companyName: '',
  reference: [],
};
export default initialGlobalState;
