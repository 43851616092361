import { ISearchState, IBusinessState } from './types';

export const initialBusinessState = (): IBusinessState => ({
  data: [],
  searchResults: initialSearchState,
  loading: false,
  error: null,
  selectedPropertyData: null,
});

export const initialSearchState: ISearchState = {
  results: [],
};
