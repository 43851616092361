import React from 'react';
import { Button, Card, Dropdown, Menu, notification } from 'antd';
import styled from 'styled-components';
import { ReactComponent as CostCardIcon } from '../assets/icons/costCardIcon.svg';
import { ReactComponent as FilesCardIcon } from '../assets/icons/filesCardIcon.svg';
import { ReactComponent as MoreOptionsIcon } from '../assets/icons/moreOptions.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store/configureStore';
import { downloadFile } from '../services/filesService/filesService.service';

interface DataCardProps {
  data: any;
  isDarkMode: boolean;
  isFiles?: boolean;
  fileName?: string;
  onMarkAsRead?: (fileName: string) => void;
}

interface DownloadFileParams {
  publisherId: string;
  fileName: string;
}

const StyledCard = styled(Card)`
  height: 46px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .ant-card-body {
    width: -webkit-fill-available;
  }
`;

const DataCard: React.FC<DataCardProps> = ({
  data,
  isDarkMode,
  isFiles,
  onMarkAsRead,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    userData: { publisherId },
  } = useSelector((state: RootState) => state.global);

  const date = isFiles ? data.date : data.costDate;
  const fileName = isFiles ? data.filepath.split('/').pop() : data.description;

  const formattedDate = new Intl.DateTimeFormat('nl-NL', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(date));

  const handleDownloadClick = async () => {
    try {
      const response = await downloadFile(data.filenameNew);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', fileName); //TODO change the diwnloadable file name asa fifileJSON available
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      if (onMarkAsRead) onMarkAsRead(data.guid);
    } catch (error: any) {
      notification.error({
        message: 'Download failed',
        description: error.message,
      });
    }
  };

  const menu = (
    <Menu style={{ background: '#656A92' }}>
      <Menu.Item key='download' onClick={handleDownloadClick}>
        Download
      </Menu.Item>
    </Menu>
  );

  // const costsMenu = (
  //   <Menu style={{ background: '#656A92' }}>
  //     <Menu.Item key='download'>No actions</Menu.Item>
  //   </Menu>
  // );

  return (
    <StyledCard
      style={{
        background: isDarkMode ? '#2B2B39' : '#F5F5F5',
        border: '1px solid #6C729D',
        height: '46px',
        display: 'flex',
        alignItems: 'center',
        width: '-webkit-fill-available',
        maxWidth: isFiles ? '850px' : 'none',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '15px',
        }}
      >
        {/* File/Cost Icon and Name */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexBasis: '40%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            style={{
              width: '24px',
              height: '24px',
              minWidth: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isFiles ? (
              <FilesCardIcon style={{ width: '100%', height: '100%' }} />
            ) : (
              <CostCardIcon style={{ width: '100%', height: '100%' }} />
            )}
          </div>
          <p
            style={{
              color: '#A3A7C3',
              margin: 0,
              paddingLeft: '8px',
              textOverflow: 'ellipsis',
              overflow: 'auto',
            }}
          >
            <strong>{fileName}</strong>
          </p>
        </div>

        {/* Invoice Number or File Identifier */}
        <div
          style={{
            flexBasis: '20%',
            textAlign: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <p style={{ color: '#656A92', margin: 0 }}>
            <strong>
              {isFiles ? data.fileDescription || '-' : data.invoiceNumber}
            </strong>
          </p>
        </div>

        {/* Date */}
        <div style={{ flexBasis: '20%', textAlign: 'center' }}>
          <p style={{ color: '#656A92', margin: 0 }}>
            <strong>{formattedDate}</strong>
          </p>
        </div>

        {/* Amount Display for Costs Only */}
        {!isFiles && (
          <div
            style={{
              flexBasis: '15%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              textWrap: 'nowrap',
            }}
          >
            <p style={{ color: '#8589C1', margin: 0 }}>
              <strong>{data.amount.toFixed(2)}€</strong>
            </p>
          </div>
        )}

        {/* More Options Icon */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexBasis: '5%',
            alignItems: 'center',
          }}
        >
          <Dropdown
            overlay={
              isFiles ? (
                menu
              ) : (
                <Menu>
                  <Menu.Item>No actions</Menu.Item>
                </Menu>
              )
            }
            trigger={['click']}
          >
            <Button
              shape='circle'
              icon={<MoreOptionsIcon />}
              ghost
              style={{ border: 'none', width: '24px', height: '24px' }}
            />
          </Dropdown>
        </div>
      </div>
    </StyledCard>
  );
};

export default DataCard;
