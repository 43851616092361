import styled from 'styled-components';
import { Input } from 'antd';

const { Search } = Input;

interface StyledProps {
  isDarkMode?: boolean;
}

export const StyledSearch = styled(Search)<StyledProps>`
  .ant-input-wrapper {
    ::placeholder {
      color: #9b9b9b;
    }
  }

  .ant-input-affix-wrapper {
    border: ${({ isDarkMode }) =>
      isDarkMode
        ? '1px solid #656A92 !important'
        : '1px solid #cacaca !important'};
    border-radius: 29px !important;
    padding: 10px 17px;
    background: ${({ isDarkMode }) => (isDarkMode ? '#0F0F1A' : '#FFFFFF')};
    color: ${({ isDarkMode }) => (isDarkMode ? '#FFFFFF' : '#000000')};
  }

  .ant-input-group-addon {
    display: none;
  }

  .ant-input-clear-icon {
    color: ${({ isDarkMode }) => isDarkMode && '#A3A7C3'};
    &:hover {
      color: ${({ isDarkMode }) => isDarkMode && '#656A92'};
    }
  }
`;
