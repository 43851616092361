import fileSlice from './FileSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { File } from './types';

const {
  search_file,
  set_error,
  set_file_data,
  set_loading,
  set_selected_file,
} = fileSlice.actions;

export const searchFile = createAsyncThunk(
  'searchFile',
  async (searchTerm: string, { dispatch }) => {
    try {
      dispatch(set_loading(true));
      dispatch(search_file(searchTerm));
      dispatch(set_loading(false));
    } catch (error: any) {
      dispatch(set_error(error.message));
    }
  }
);

export const setFileData = createAsyncThunk(
  'setFileData',
  async (data: File[], { dispatch }) => {
    try {
      dispatch(set_loading(true));
      dispatch(set_file_data(data));
      dispatch(set_loading(false));
    } catch (error: any) {
      dispatch(set_error(error.message));
    }
  }
);

export const setSelcetedFile = createAsyncThunk(
  'setFileData',
  async (data: File, { dispatch }) => {
    try {
      dispatch(set_loading(true));
      dispatch(set_selected_file(data));
      dispatch(set_loading(false));
    } catch (error: any) {
      dispatch(set_error(error.message));
    }
  }
);
