export const calculatePaginationOptions = (totalItems: number, step = 24) => {
  let options = [];
  let maxOption =
    totalItems < step ? step : Math.ceil(totalItems / step) * step;
  if (maxOption > totalItems && maxOption - totalItems > step) {
    maxOption -= step;
  }
  for (let option = step; option <= maxOption; option += step) {
    if (option <= totalItems) {
      options.push(option);
    }
  }
  return options;
};
