import { configureStore, createSlice } from '@reduxjs/toolkit';
import rootReducer from './reducers';

const enhancers: any[] = [];

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  enhancers,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
