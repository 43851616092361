import authHeader from '../authenticationService/auth-header';

const selectedCompany = localStorage.getItem('selectedCompany') || '';
const queryParams = new URLSearchParams();
queryParams.append('companyGuId', selectedCompany);
const queryCompanyguid = queryParams.toString();
export const getJurisdictionsList = async (queryString: any) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/ip/jurisdictions?${queryCompanyguid}&${queryString}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching cards data: ' + error.message);
  }
};

export const getJurisdictionCounts = async (queryString: any) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/ip/counts/jurisdiction?${queryCompanyguid}&${queryString}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching cards data: ' + error.message);
  }
};

export const getFileTypeCounts = async (queryString: any) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/ip/counts/fileType?${queryCompanyguid}&${queryString}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching cards data: ' + error.message);
  }
};

export const getPublicationCounts = async (queryString: any) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/ip/counts/dateFiled?${queryCompanyguid}&${queryString}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching cards data: ' + error.message);
  }
};

export const getTopCompanies = async (queryString: any) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/ip/counts/applicant?${queryCompanyguid}&${queryString}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching cards data: ' + error.message);
  }
};

export const getTopInventors = async (queryString: any) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/ip/counts/inventor?${queryCompanyguid}&${queryString}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching cards data: ' + error.message);
  }
};

export const getCostsPerJurisdiction = async (queryString: any) => {
  try {
    const response = await fetch(
      `https://api.ipmanagement.io/cost/per-jurisdiction?${queryCompanyguid}&${queryString}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching cards data: ' + error.message);
  }
};
