import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Card, Spin, Empty, Tooltip } from 'antd';
import { scaleOrdinal } from 'd3-scale';
import { schemeSet2 } from 'd3-scale-chromatic';

interface PieChartProps {
  title: string;
  isCosts: boolean;
  data: any;
  loading: boolean;
}

const PieChart = ({ title, isCosts, data, loading }: PieChartProps) => {
  const aggregateData = (data: any[]) => {
    return data.reduce((acc, item) => {
      const existing = acc.find((i: any) => i.groupName === item.groupName);
      if (existing) {
        existing.count += item.count;
      } else {
        acc.push({ ...item });
      }
      return acc;
    }, [] as { groupName: string; count: number }[]);
  };

  const transformResponseToCosts = (response: any) => {
    return Object.keys(response).map((key) => ({
      groupName: response[key].jurisdiction,
      count: response[key].amount,
    }));
  };

  // Aggregate and sort data
  const aggregatedData = isCosts
    ? transformResponseToCosts(data)
    : aggregateData(data);

  const sortedData = aggregatedData.sort((a: any, b: any) => b.count - a.count); // Sort largest to smallest

  const customTooltip = ({ datum }: any) => (
    <div
      style={{
        padding: '5px 10px',
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '3px',
        color: '#000',
      }}
    >
      {datum.id}: {isCosts ? `EUR ${datum.value}` : `${datum.value} cases`}
    </div>
  );

  const colorScale = scaleOrdinal(schemeSet2).domain(
    sortedData.map((item: any) => item.groupName)
  );

  return (
    <Card
      title={title}
      style={{ width: '500px', height: '500px' }}
      bodyStyle={{ padding: 0 }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column', // Ensure legends are below the chart
          alignItems: 'center',
        }}
      >
        {loading ? (
          <Spin size='large' style={{ marginTop: '170px' }} />
        ) : Object.keys(data).length === 0 ? (
          <Empty
            description='No data to display'
            style={{ marginTop: '120px' }}
          />
        ) : (
          <>
            {/* Chart */}
            <div style={{ height: '350px', width: '100%' }}>
              <ResponsivePie
                data={sortedData.map((item: any) => ({
                  id: item.groupName,
                  label: item.groupName,
                  value: item.count,
                  color: colorScale(item.groupName),
                }))}
                colors={({ id }) => colorScale(id as string)} // Use the color scale for chart
                margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                arcLabelsRadiusOffset={1.2}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor='transparent'
                arcLinkLabelsThickness={0}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]],
                }}
                tooltip={customTooltip}
              />
            </div>
            {/* Custom Legends */}
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                padding: '0 10px',
                marginBottom: '10px',
              }}
            >
              {sortedData.map((item: any, index: number) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '5px', // Space between legend items
                    borderRadius: '5px', // Optional: rounded corners for legend items
                  }}
                >
                  {/* Circle with matching color */}
                  <span
                    style={{
                      width: '12px', // Fixed circle size
                      height: '12px',
                      borderRadius: '50%',
                      backgroundColor: colorScale(item.groupName), // Match the chart color
                      display: 'inline-block',
                      marginRight: '8px',
                    }}
                  ></span>
                  {/* Legend text with Ant Design Tooltip */}
                  <Tooltip
                    title={item.groupName}
                    color='white'
                    overlayInnerStyle={{
                      color: '#000', // Set tooltip text color to black
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        color: '#333',
                        whiteSpace: 'nowrap', // Prevent text wrapping
                        overflow: 'hidden', // Hide overflow text
                        textOverflow: 'ellipsis', // Add ellipsis to truncated text
                        maxWidth: '100px', // Limit the text width for ellipsis
                        display: 'inline-block', // Ensure inline behavior for ellipsis
                      }}
                    >
                      {item.groupName}
                    </span>
                  </Tooltip>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Card>
  );
};

export default PieChart;
