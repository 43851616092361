import React, { useState, useEffect } from 'react';
import { Button, Form, Checkbox, Typography, Modal } from 'antd';
import { Wrapper } from './FinalizeOrder.styled';
import authHeader from '../../services/authenticationService/auth-header';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

const { Link } = Typography;
interface FinalizeOrderProps {
  setCheckboxState: (checked: boolean) => void;
}

const FinalizeOrder: React.FC<FinalizeOrderProps> = ({ setCheckboxState }) => {
  const [isChecked, setIsChecked] = useState(false);

  const {
    userData: { publisherId, email },
  } = useSelector((state: RootState) => state.global);

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
    setCheckboxState(e.target.checked);
  };

  return (
    <Wrapper>
      <Form.Item>
        <Checkbox checked={isChecked} onChange={handleCheckboxChange}>
          Click here to accept that you have read and agree on the terms and
          conditions of the{' '}
          <Link
            href='https://termly.io/products/terms-and-conditions-generator/'
            target='_blank'
          >
            IP document.
          </Link>
        </Checkbox>
      </Form.Item>
      <br />
      <br />
    </Wrapper>
  );
};

export default FinalizeOrder;
