import React, { useEffect } from 'react';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { setPageInfo } from '../../store/ducks/Global/operations';
import SearchShortcuts from '../../components/SearchShortcuts';
import { shortcuts, ShortCutType } from '../../utils/searchShortcuts';
import ChoroplethMap from '../../components/ChoroplethMap';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    userData: { publisherId },
  } = useSelector((state: RootState) => state.global);

  const subTitles = ['IP portfolio active', 'Archive'];

  const activeShortcuts = shortcuts.filter(
    (shortcut) => shortcut.filegroup === 'IP portfolio active'
  );

  const archiveShortcuts = shortcuts.filter(
    (shortcut) => shortcut.filegroup === 'Archive'
  );

  useEffect(() => {
    dispatch(
      setPageInfo({
        currentPageName: 'Dashboard',
      })
    );
  }, [dispatch]);

  const handleShortcutClick = (shortcut: ShortCutType) => {
    // Navigate to the IP page with the shortcut label as a query parameter
    navigate(`/ip?filter=${encodeURIComponent(shortcut.label)}`);
  };

  return (
    <SearchShortcuts
      title='Search shortcuts'
      activeShortcuts={activeShortcuts}
      handleShortcutClick={handleShortcutClick}
    />
  );
};

export default Dashboard;
