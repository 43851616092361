export const environment = {
  production: false,
  serverUrl: '',
  loginUrl: 'https://auth.ipmanagement.io/auth/login',
  patentsUrl: 'https://api.ipmanagement.io/patents',
  userData: 'https://api.ipmanagement.io/users',
  ipCards: 'https://api.ipmanagement.io/ip/cards',
  ipCardDetails: 'https://api.ipmanagement.io/ip/details',
  tasksData: 'https://api.ipmanagement.io/tasks/instructions',
  invoicePreview: 'https://api.ipmanagement.io/invoice/preview',
  companies: 'https://api.ipmanagement.io/companies',
  signUpUrl: '',
  application: {},
  config: {
    api: false,
    url: './assets/params/json/crud/',
  },
};
