import { combineReducers } from 'redux';
import instructionsWizardSlice from './ducks/InstructionsWizard';
import globalSlice from './ducks/Global';
import IPropertySlice from './ducks/IProperty';
import BusinessSlice from './ducks/Business/BusinessSlice';
import { fileSlice } from './ducks/File';

export default combineReducers({
  instructions: instructionsWizardSlice.reducer,
  global: globalSlice.reducer,
  iProperty: IPropertySlice.reducer,
  business: BusinessSlice.reducer,
  file: fileSlice.reducer,
});
