import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './views/Login/Login';
import './App.css';
import OrderPortalLayout from './layouts/OrderPortalLayout';
import Dashboard from './views/Dashboard';
import Orders from './views/Orders';
import Tasks from './views/Tasks';
import InstructionWizard from './views/InstructionWizard';
import IProperty from './views/IProperty';
import PropertyDetailedView from './views/DocumentDetailedView';
import Business from './views/Business';
import FileComponent from './views/File/File';
import Financial from './views/Financial';
import RequireAuth from './components/hoc/RequireAuth';
import ScrollTopButton from './components/arrowButton';
import Insights from './views/Insights';

function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path='/azure-login-callback' element={<LoginForm />} />
          <Route path='/google-login-callback' element={<LoginForm />} />
          <Route path='/linkedin-login-callback' element={<LoginForm />} />
          <Route path='/' element={<LoginForm />} />
          <Route path='/login' element={<LoginForm />} />

          <Route
            path='/'
            element={
              <RequireAuth>
                <OrderPortalLayout />
              </RequireAuth>
            }
          >
            <Route
              index
              path='dashboard'
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path='files'
              element={
                <RequireAuth>
                  <FileComponent />
                </RequireAuth>
              }
            />
            <Route
              path='ip'
              element={
                <RequireAuth>
                  <IProperty />
                </RequireAuth>
              }
            />
            <Route
              path='ip/:id'
              element={
                <RequireAuth>
                  <PropertyDetailedView />
                </RequireAuth>
              }
            />
            <Route
              path='/ip/insights'
              element={
                <RequireAuth>
                  <Insights />
                </RequireAuth>
              }
            />
            <Route
              path='business'
              element={
                <RequireAuth>
                  <Business />
                </RequireAuth>
              }
            />
            <Route
              path='financial'
              element={
                <RequireAuth>
                  <Financial />
                </RequireAuth>
              }
            />
            <Route
              path='tasks'
              element={
                <RequireAuth>
                  <Tasks />
                </RequireAuth>
              }
            />
            <Route
              path='tasks/wizard'
              element={
                <RequireAuth>
                  <InstructionWizard />
                </RequireAuth>
              }
            />
            <Route
              path='orders'
              element={
                <RequireAuth>
                  <Orders />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </div>
      <ScrollTopButton />
    </Router>
  );
}

export default App;
