import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  ConfigProvider,
  Modal,
  Tag,
  Timeline,
  notification,
  Spin,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/configureStore';
import { Image, List } from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as SummaryLightIcon } from '../../assets/icons/summaryLight.svg';
import { ReactComponent as SummaryDarkIcon } from '../../assets/icons/summaryDark.svg';
import { ReactComponent as NoDataLight } from '../../assets/noDataFile.svg';
import { ReactComponent as NoDataDark } from '../../assets/noDataFileDark.svg';
import { setSelectedPropertyData } from '../../store/ducks/IProperty/operations';
import { setPageInfo } from '../../store/ducks/Global/operations';
import {
  detailsImageUrlGeneration,
  getCardDetailsData,
} from '../../services/iPropertyService/iProperty.service';
import bigImg from '../../assets/bigImg.jpg';
import { fetchCostsPerIp } from '../../services/costsService/costsService.service';
import { downloadFile } from '../../services/filesService/filesService.service';

interface StyledCarddProps {
  isDarkMode: boolean;
}

interface ImageData {
  filename: string;
  title: string;
  design: number;
  view: number;
  imageURL?: string;
  thumbnailURL?: string;
}

type GroupedImages = ImageData[][];

const ParentDiv = styled.div`
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
`;

const StyledTimeline = styled(Timeline)<StyledCarddProps>`
  .ant-timeline-item-content {
    color: ${({ isDarkMode }) => (isDarkMode ? '#FFFFFF' : '#6C729D')};
    font-size: 18px;
    margin-top: -8px;
  }
`;

const StyledCardd = styled(Card)<StyledCarddProps>`
  height: auto
  width: 100%;
  font-family: Futura;
  border: none;

  background-color: ${({ isDarkMode }) => (isDarkMode ? '#0f0f1a' : 'white')};

  border-radius: 10px;


  .ant-card-head {
    padding: 0;
    border: none;
  }

  .ant-card-head-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: 20px;
    }
  }
`;

const StyledTag = styled(Tag)<StyledCarddProps>`
  width: 119px;
  height: 35px;
  font-family: Futura;
  font-size: 18px;
  font-weight: 450;
  text-align: center;
  color: #0f0f1a !important;
  align-content: center;
  border-radius: 0px 15px 15px 0px;
  margin: 0;
  padding: 0;
`;

const StyledList = styled.ul`
  font-size: 18px;
  color: #656a92;
  margin-top: 20px;

  li:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Key = styled.span`
  /* Float the key to the left */
  float: left;
  font-size: 18px;
`;

const Value = styled.span`
  /* Float the value to the right */
  float: right;
  font-size: 18px;
`;

interface DetailsData {
  [key: string]: any;
}

const PropertyDetailedView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState('details');
  const dispatch = useDispatch<AppDispatch>();
  const [currentPreview, setCurrentPreview] = useState(0);
  const navigate = useNavigate();
  const { selectedPropertyData } = useSelector(
    (state: RootState) => state.iProperty
  );
  //fetched data
  const [detailsData, setDetailsData] = useState<DetailsData | null>(null); // Store fetched details here
  const [financialToDisplay, setFinancialToDisplay] = useState<any[]>([]);
  const [filesToDisplay, setFilesToDisplay] = useState<any[]>([]);
  //images
  const [imageGroups, setImageGroups] = useState<GroupedImages>([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { uitheme } = useSelector((state: RootState) => state.global);
  const isDarkMode = uitheme === 'dark';

  const [loading, setLoading] = useState(true);
  const [isFilesloading, setFilesLoading] = useState(true);
  const [isImgLoading, setIsImgLoading] = useState(true);

  useEffect(() => {
    if (selectedPropertyData) {
      dispatch(setSelectedPropertyData(selectedPropertyData));
    }
  }, [dispatch, selectedPropertyData]);

  useEffect(() => {
    // Define fetchDetails to optionally accept a metadataId parameter
    const fetchDetails = async (metadataId?: string) => {
      const idToFetch = metadataId || selectedPropertyData?.ipMetadataGuid;

      if (!idToFetch) {
        return;
      }

      try {
        const data = await getCardDetailsData(idToFetch);
        setDetailsData(data);
        setFilesToDisplay(data?.files || []);
        setFinancialToDisplay(data?.financial || []);
      } catch (error: any) {
        notification.error({
          message: 'Failed to fetch details data:',
          description: error.message,
        });
      } finally {
        setFilesLoading(false);
        setLoading(false);
      }
    };

    // Check if selectedPropertyData exists in Redux or fallback to localStorage
    if (!selectedPropertyData) {
      const savedData = localStorage.getItem('selectedPropertyData');

      if (savedData) {
        // If data is found in localStorage, parse it, set in Redux, and fetch details
        const parsedData = JSON.parse(savedData);
        dispatch(setSelectedPropertyData(parsedData));
        fetchDetails(parsedData.ipMetadataGuid); // Call with metadataId from localStorage
      } else if (id) {
        // If data is not in Redux or localStorage, use the id from URL to fetch data
        fetchDetails(id); // Call with metadataId from URL
      }
    } else {
      // Fetch details directly if selectedPropertyData is already in Redux
      fetchDetails(); // Call without parameter to use selectedPropertyData from Redux
    }
  }, [dispatch, selectedPropertyData, id]);

  const [imageURL, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!detailsData?.images) {
      setIsImgLoading(false);
      return;
    }

    const fetchImageGroups = async () => {
      setIsImgLoading(true);
      const groups: GroupedImages = [];
      const BATCH_SIZE = 3; // Adjust batch size as needed

      for (let i = 0; i < detailsData.images.length; i += BATCH_SIZE) {
        const batch = detailsData.images.slice(i, i + BATCH_SIZE);

        const batchResults = await Promise.all(
          batch.map(async (group: any) => {
            return Promise.all(
              group.map(async (image: any) => {
                try {
                  const response = await detailsImageUrlGeneration(
                    image.filename
                  );
                  return {
                    filename: image.filename,
                    imageURL: response.imageURL,
                    thumbnailURL: response.thumbnailURL,
                    title: image['image-title'],
                    design: image.design,
                    view: image.view,
                  };
                } catch (error) {
                  notification.error({
                    message: 'Image Fetch Failed',
                    description: `Could not fetch image URL for ${image['image-title']}.`,
                  });
                  return {
                    filename: image.filename,
                    imageURL: '',
                    thumbnailURL: '',
                    title: image['image-title'],
                    design: image.design,
                    view: image.view,
                  };
                }
              })
            );
          })
        );

        groups.push(...batchResults);
      }

      setImageGroups(groups);
      setIsImgLoading(false);
    };

    fetchImageGroups();
  }, [detailsData]);

  useEffect(() => {
    dispatch(
      setPageInfo({
        currentPageName: 'IP management',
      })
    );
  }, [dispatch]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const flatImages = imageGroups.flat();

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleThumbnailPrevClick = () => {
    setCurrentGroupIndex((prevIndex) =>
      prevIndex === 0 ? imageGroups.length - 1 : prevIndex - 1
    );
    setCurrentPreview(0);
  };

  const handleThumbnailNextClick = () => {
    setCurrentGroupIndex((prevIndex) =>
      prevIndex === imageGroups.length - 1 ? 0 : prevIndex + 1
    );
    setCurrentPreview(0);
  };

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setIsModalVisible(true);
  };

  const handleNextClick = () => {
    if (currentImageIndex < imageGroups[currentGroupIndex].length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else if (currentGroupIndex < imageGroups.length - 1) {
      // Move to next group if at the end of the current group
      setCurrentGroupIndex(currentGroupIndex + 1);
      setCurrentImageIndex(0);
    }
  };

  const handlePrevClick = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    } else if (currentGroupIndex > 0) {
      // Move to previous group if at the start of the current group
      setCurrentGroupIndex(currentGroupIndex - 1);
      setCurrentImageIndex(imageGroups[currentGroupIndex - 1].length - 1);
    }
  };

  const handleNextGroupClick = () => {
    if (currentGroupIndex < imageGroups.length - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
      setCurrentImageIndex(0); // Set to the first image of the new group
    }
  };

  const handlePrevGroupClick = () => {
    if (currentGroupIndex > 0) {
      setCurrentGroupIndex(currentGroupIndex - 1);
      setCurrentImageIndex(0); // Set to the first image of the new group
    }
  };

  const currentGroup = imageGroups[currentGroupIndex] || [];

  const images = [
    {
      src: bigImg,
      alt: 'Image 1',
    },
    // { src: image2, alt: 'Image 2' },
    // { src: image3, alt: 'Image 3' },
  ];

  const formatKey = (key: string) => {
    key = key.startsWith('@') ? key.slice(1) : key;

    if (key.includes('-')) {
      return key.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
    } else {
      return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
    }
  };

  const renderSection = (
    key: any,
    value: any,
    margin?: any,
    newP?: boolean
  ) => {
    console.log('keeey', key);
    return (
      <div key={key} style={{ marginBottom: margin ? margin : '20px' }}>
        {key && (
          <div style={{ fontSize: '18px', color: '#656A92' }}>
            {formatKey(key)}
          </div>
        )}
        <div style={{ fontSize: '18px', color: '#A3A7C3', fontWeight: 500 }}>
          {Array.isArray(value)
            ? newP
              ? value.map((item, index) => (
                  <div key={index}>
                    {item}
                    {index !== value.length - 1 && <br />}
                  </div>
                ))
              : value.join(', ')
            : value}
        </div>
      </div>
    );
  };

  const renderTitle = (key: any) => (
    <div
      style={{
        color: isDarkMode ? 'white' : '#6C729D',
        borderBottom: '1px solid #656A92',
        paddingBottom: '15px',
        marginBottom: '15px',
        fontSize: '22px',
        fontWeight: 550,
      }}
    >
      {formatKey(key)}
    </div>
  );

  const renderDesignations = (designations: any[]) => {
    return (
      <StyledList style={{ paddingLeft: '15px' }}>
        {designations.map((designation, index) => (
          <li key={index}>
            {designation['@country']} {designation['@status']} (
            {designation['@date']})
          </li>
        ))}
      </StyledList>
    );
  };

  const handleFileDownload = async (filename: string) => {
    setFilesLoading(true);
    try {
      const response = await downloadFile(filename);
      const blob = await response.blob();
      // window.open(response, '_blank');
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      notification.error({
        message: 'File Download Failed',
        description: `Could not download file ${filename}. Please try again later.`,
      });
    } finally {
      setFilesLoading(false);
    }
  };

  return (
    <StyledCardd
      isDarkMode={isDarkMode}
      bodyStyle={{ padding: '20px 60px' }}
      size='small'
      style={{
        width: '100%',
        minHeight: '1430px',
        borderRadius: '10px',
        backgroundColor: uitheme == 'dark' ? '#0F0F1A' : '#FCFCFC',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          key='details'
          style={{
            display: 'flex',
            flexDirection: 'column',
            color: 'white',
            width: '55%',
          }}
        >
          {selectedPropertyData?.card?.title && (
            <span
              style={{
                fontSize: '24px',
                fontWeight: 700,
                color: isDarkMode ? 'white' : '#6C729D',
                marginBottom: '30px',
              }}
            >
              {selectedPropertyData.card.title}
            </span>
          )}

          {detailsData?.abstract &&
            renderSection('Abstract', detailsData.abstract)}

          {/* Render Fetched Details Data */}
          {/* Render Bibliographic Information Section */}
          {detailsData?.['bibliographic-information'] && (
            <div style={{ marginBottom: '20px' }}>
              {Object.entries(detailsData['bibliographic-information']).map(
                ([subKey, subValue]) => {
                  if (subKey.toLowerCase() === 'title') return null; // Skip title here
                  return renderSection(subKey, subValue);
                }
              )}
              {detailsData?.reference &&
                renderSection('Reference', detailsData.reference)}
            </div>
          )}

          {/* Always show Images Section under Bibliographic Information */}
          {detailsData?.images?.length && (
            <div style={{ marginBottom: '35px' }}>
              <div
                style={{
                  color: isDarkMode ? 'white' : '#6C729D',
                  borderBottom: '1px solid #656A92',
                  paddingBottom: '15px',
                  fontSize: '22px',
                  fontWeight: 550,
                }}
              >
                Images
              </div>
              {isImgLoading ? (
                <Spin
                  tip='Loading...'
                  style={{
                    margin: '20px auto',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    gap: '30px',
                    paddingTop: '20px',
                    alignItems: 'center',
                  }}
                >
                  <DoubleLeftOutlined
                    onClick={handlePrevGroupClick}
                    style={{
                      fontSize: '24px',
                      color: '#A3A7C3',
                      cursor: currentGroupIndex > 0 ? 'pointer' : 'not-allowed',
                    }}
                    disabled={isImgLoading || currentGroupIndex <= 0}
                  />
                  <LeftOutlined
                    onClick={handlePrevClick}
                    style={{
                      fontSize: '24px',
                      color: '#A3A7C3',
                      cursor:
                        currentGroupIndex > 0 || currentImageIndex > 0
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    disabled={
                      isImgLoading ||
                      (currentGroupIndex <= 0 && currentImageIndex <= 0)
                    }
                  />
                  <List
                    dataSource={imageGroups[currentGroupIndex] || []}
                    renderItem={(item, index) => (
                      <List.Item
                        style={{
                          display: 'inline-block',
                          marginRight: 16,
                        }}
                      >
                        <Image
                          src={item.thumbnailURL}
                          alt={item.title}
                          style={{
                            width: 160,
                            height: 104,
                            objectFit: 'cover',
                            cursor: 'pointer',
                            borderRadius: '10px',
                            border:
                              index === currentImageIndex
                                ? '2px solid #A3A7C3'
                                : 'none',
                          }}
                          preview={false}
                          onClick={() => handleImageClick(index)}
                        />
                      </List.Item>
                    )}
                  />
                  <RightOutlined
                    onClick={handleNextClick}
                    style={{
                      fontSize: '24px',
                      color: '#A3A7C3',
                      cursor:
                        currentGroupIndex < imageGroups.length - 1 ||
                        currentImageIndex <
                          imageGroups[currentGroupIndex]?.length - 1
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    disabled={
                      isImgLoading ||
                      (currentGroupIndex >= imageGroups.length - 1 &&
                        currentImageIndex >=
                          imageGroups[currentGroupIndex]?.length - 1)
                    }
                  />
                  <DoubleRightOutlined
                    onClick={handleNextGroupClick}
                    style={{
                      fontSize: '24px',
                      color: '#A3A7C3',
                      cursor:
                        currentGroupIndex < imageGroups.length - 1
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    disabled={
                      isImgLoading ||
                      currentGroupIndex >= imageGroups.length - 1
                    }
                  />
                </div>
              )}
              {isModalVisible && (
                <Modal
                  visible={isModalVisible}
                  onCancel={closeModal}
                  footer={null}
                  destroyOnClose
                  width={800}
                  centered
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <LeftOutlined
                      onClick={handlePrevClick}
                      style={{
                        fontSize: '24px',
                        color: '#1890ff',
                        cursor: 'pointer',
                      }}
                    />
                    <Image
                      src={
                        imageGroups[currentGroupIndex][currentImageIndex]
                          ?.imageURL
                      }
                      alt={
                        imageGroups[currentGroupIndex][currentImageIndex]?.title
                      }
                      preview={false}
                    />
                    <RightOutlined
                      onClick={handleNextClick}
                      style={{
                        fontSize: '24px',
                        color: '#1890ff',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                </Modal>
              )}
            </div>
          )}

          {/* Render Dynamic Sections */}
          {detailsData &&
            Object.entries(detailsData).map(([key, value]) => {
              if (
                key === 'bibliographic-information' || // Skip already rendered section
                key === 'abstract' || // Abstract rendered separately
                key === 'summary' || // Summary not rendered in this section
                key === 'images' || // Images handled separately
                key === 'business' || // Business data is not included in this section
                key === 'financial' || // Financial data is not included in this section
                key === 'events' ||
                key === 'reference' ||
                key === 'files' ||
                key === 'financial' ||
                key === 'business'
              ) {
                return null;
              }

              if (key === '@related-parties') {
                // Render 'Related Parties' without a section title
                return Object.entries(value as Record<string, any>).map(
                  ([subKey, subValue]) => renderSection(subKey, subValue, 30)
                );
              }

              if (key === 'designations' && Array.isArray(value)) {
                return (
                  <div key={key} style={{ marginBottom: '25px' }}>
                    {renderTitle(key)}
                    <div> {renderDesignations(value)}</div>
                  </div>
                );
              }

              return (
                <div key={key} style={{ marginBottom: '25px' }}>
                  {renderTitle(key)}
                  <div>
                    {/* Render sub-sections dynamically */}
                    {typeof value === 'object' && !Array.isArray(value)
                      ? Object.entries(value as Record<string, any>).map(
                          ([subKey, subValue]) =>
                            renderSection(subKey, subValue)
                        )
                      : Array.isArray(value) &&
                        value.some((item) => typeof item === 'object')
                      ? value.map((item, index) => (
                          <div key={index}>
                            {typeof item === 'object' && item !== null
                              ? Object.entries(item as Record<string, any>).map(
                                  ([nestedKey, nestedValue]) =>
                                    renderSection(nestedKey, nestedValue)
                                )
                              : renderSection('', item)}
                          </div>
                        ))
                      : renderSection('', value, '', true)}
                  </div>
                </div>
              );
            })}

          {/* Render Events Section at the end */}
          {detailsData?.events && (
            <div>
              {renderTitle('Events')}
              <div>
                <ConfigProvider
                  theme={{
                    components: {
                      Timeline: { tailColor: '#A3A7C3', tailWidth: 2 },
                    },
                  }}
                >
                  <StyledTimeline
                    style={{
                      height: 'auto',
                      fontFamily: 'Futura',
                      marginTop: '30px',
                    }}
                    isDarkMode={isDarkMode}
                  >
                    {detailsData.events.map((event: any, index: any) => (
                      <StyledTimeline.Item key={index} color='#656A92'>
                        <span>{event.date}</span>
                        <span style={{ marginLeft: '35px' }}>
                          {event.description}
                        </span>
                      </StyledTimeline.Item>
                    ))}
                  </StyledTimeline>
                </ConfigProvider>
              </div>
            </div>
          )}
        </div>
        <div
          key='additional'
          style={{
            width: '40%',
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',
          }}
        >
          <div key='summary'>
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: isDarkMode ? '#FFFFFF' : '#656A92',
                margin: '0px 0px 15px 30px',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {isDarkMode ? <SummaryDarkIcon /> : <SummaryLightIcon />}
              <span style={{ marginLeft: '10px' }}>Summary</span>
            </span>
            <div
              style={{
                backgroundColor: isDarkMode ? '#363853' : '#F5F5F5',
                border: !isDarkMode ? '2px solid #E5E5E5' : 'none',
                boxShadow: !isDarkMode ? '0px 8px 16px 0px #00000014' : 'none',
                width: '100%',
                height: 'fit-content',
                padding: '30px 25px',
                borderRadius: '13px ',
              }}
            >
              <StyledList
                style={{
                  listStyleType: 'disc',
                  paddingLeft: '20px',
                  fontSize: '18px',
                  color: isDarkMode ? '#FFFFFF' : '#7E7E7E',
                  margin: 0,
                }}
              >
                {detailsData?.summary?.map(
                  (item: any, index: any) =>
                    !!item.length && <li key={index}>{item}</li>
                )}
              </StyledList>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              height: 'fit-content',
              padding: '30px 25px',
              borderRadius: '13px ',
              display: 'flex',
              flexDirection: 'column',
              gap: '35px',
              backgroundColor: isDarkMode ? '#363853' : '#F5F5F5',
              border: !isDarkMode ? '2px solid #E5E5E5' : 'none',
              boxShadow: !isDarkMode ? '0px 8px 16px 0px #00000014' : 'none',
            }}
          >
            <div>
              <div
                style={{
                  color: isDarkMode ? '#FFFFFF' : '#656A92',
                  borderBottom: '1px solid #A9A9A9',
                  paddingBottom: '15px',
                  fontSize: '22px',
                }}
              >
                <b>Business</b>
                <Button
                  shape='round'
                  size='large'
                  style={{
                    marginLeft: '10px',
                    color: isDarkMode ? '#CBD2D9' : '#656A92',
                    background: isDarkMode ? '#0F0F1A' : 'none',
                    border: isDarkMode ? 'none' : '1px solid #656A92',
                    fontWeight: 600,
                    display: 'none',
                  }}
                  onClick={() => {}}
                >
                  See all
                </Button>
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                  }}
                >
                  <div
                    style={{
                      color: isDarkMode ? '#A3A7C3' : '#7E7E7E',
                      fontSize: '18px',
                      width: '75%',
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 550,
                        marginBottom: 0,
                      }}
                    >
                      No business data available
                    </p>
                    {/* <p
                        style={{
                          marginTop: 0,
                        }}
                      >
                        You can add details&nbsp;
                        <a
                          style={{
                            color: '#656A92',
                            marginTop: 0,
                          }}
                        >
                          <u>here</u>
                        </a>
                      </p> */}
                  </div>
                  <div>{isDarkMode ? <NoDataDark /> : <NoDataLight />} </div>
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  color: '#656A92',
                  borderBottom: '1px solid #A9A9A9',
                  paddingBottom: '15px',
                  fontSize: '22px',
                }}
              >
                <b>Financial (costs)</b>
                <Button
                  shape='round'
                  size='large'
                  style={{
                    marginLeft: '10px',
                    color: isDarkMode ? '#CBD2D9' : '#656A92',
                    background: isDarkMode ? '#0F0F1A' : 'none',
                    border: isDarkMode ? 'none' : '1px solid #656A92',
                    fontWeight: 600,
                    display: 'none',
                  }}
                  onClick={() => navigate('/financial')}
                >
                  See all
                </Button>
              </div>
              <div>
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100vh',
                      width: '100%',
                    }}
                  >
                    <Spin size='large' />
                  </div>
                ) : financialToDisplay?.length > 0 ? (
                  <StyledList
                    style={{
                      marginTop: '15px',
                      listStyleType: 'disc',
                      paddingLeft: '20px',
                      fontSize: '18px',
                      color: isDarkMode ? '#FFFFFF' : '#7E7E7E',
                    }}
                  >
                    {financialToDisplay
                      .sort((a, b) => b.description - a.description) // Sort by date descending
                      .map((item) => (
                        <li key={item.id}>
                          {item.description}: {item.currency}{' '}
                          {Math.abs(item.amount).toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </li>
                      ))}
                  </StyledList>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '15px',
                    }}
                  >
                    <div
                      style={{
                        color: isDarkMode ? '#A3A7C3' : '#7E7E7E',
                        fontSize: '18px',
                        width: '75%',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 550,
                          marginBottom: 0,
                        }}
                      >
                        No Financial data available
                      </p>
                    </div>
                    <div>{isDarkMode ? <NoDataDark /> : <NoDataLight />}</div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div
                style={{
                  color: '#656A92',
                  borderBottom: '1px solid #A9A9A9',
                  fontSize: '22px',
                  paddingBottom: '15px',
                }}
              >
                <b>Files</b>
                <Button
                  shape='round'
                  size='large'
                  style={{
                    marginLeft: '10px',
                    color: isDarkMode ? '#CBD2D9' : '#656A92',
                    background: isDarkMode ? '#0F0F1A' : 'none',
                    border: isDarkMode ? 'none' : '1px solid #656A92',
                    fontWeight: 600,
                    display: 'none',
                  }}
                  onClick={() => navigate('/files')}
                >
                  See all
                </Button>
              </div>
              <div>
                {isFilesloading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100vh',
                      width: '100%',
                    }}
                  >
                    <Spin size='large' />
                  </div>
                ) : filesToDisplay && filesToDisplay.length > 0 ? (
                  <StyledList
                    style={{
                      marginTop: '15px',
                      listStyleType: 'disc',
                      paddingLeft: '20px',
                      fontSize: '18px',
                      color: isDarkMode ? '#FFFFFF' : '#7E7E7E',
                    }}
                  >
                    {filesToDisplay.map((file: any) => (
                      <li key={file.filename}>
                        <span
                          style={{
                            color: isDarkMode ? '#A3A7C3' : '#6C729D',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                          onClick={() => handleFileDownload(file.filename)}
                        >
                          {file.description}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: '14px',
                            color: isDarkMode ? '#A3A7C3' : '#7E7E7E',
                          }}
                        >
                          {file.date}
                        </span>
                      </li>
                    ))}
                  </StyledList>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '15px',
                    }}
                  >
                    <div
                      style={{
                        color: isDarkMode ? '#A3A7C3' : '#7E7E7E',
                        fontSize: '18px',
                        width: '75%',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 550,
                          marginBottom: 0,
                        }}
                      >
                        You have no documents in system
                      </p>
                    </div>
                    <div>{isDarkMode ? <NoDataDark /> : <NoDataLight />} </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledCardd>
  );
};

export default PropertyDetailedView;
