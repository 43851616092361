import globalSlice from './globalSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';

const { set_user_data, set_error, set_page_info, set_ui_theme } =
  globalSlice.actions;

export const setUserData = createAsyncThunk(
  'globalProps',
  async (data: any, { dispatch }) => {
    try {
      dispatch(set_user_data(data));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export const setUiTheme = createAsyncThunk(
  'globalProps',
  async (data: string, { dispatch }) => {
    try {
      console.log('datatatatat', data);
      dispatch(set_ui_theme(data));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export const setPageInfo = createAsyncThunk(
  'globalProps',
  async (data: any, { dispatch }) => {
    try {
      dispatch(set_page_info(data));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export default {
  setUserData,
  setPageInfo,
  setUiTheme,
};
