import { environment } from '../../environments/environment';
import authHeader from '../authenticationService/auth-header';

export const fetchInvoicePreview = async (
  totalAmount: number,
  taxableAmount: number
) => {
  const userData = localStorage.getItem('loginInfo');
  let userId = '';
  if (userData) userId = JSON.parse(userData)?.userId;

  try {
    const queryParams = new URLSearchParams({
      totalAmount: totalAmount.toString(),
      taxableAmount: taxableAmount.toString(),
    });

    const url = `${
      environment.invoicePreview
    }/${userId}?${queryParams.toString()}`;

    const response = await fetch(url, {
      headers: authHeader(),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const htmlContent = await response.text();

    return htmlContent;
  } catch (error: any) {
    throw new Error('Error fetching invoice preview: ' + error.message);
  }
};
