export default function authHeader() {
  const userData = localStorage.getItem('loginInfo');
  let user = null;
  if (userData) user = JSON.parse(userData);

  if (user && user.accessToken) {
    return { Authorization: 'Bearer ' + user.accessToken };
  } else {
    return { Authorization: '' };
  }
}
