import { Breadcrumb } from 'antd';
import styled from 'styled-components';

export const StyledBreadcrumb = styled(Breadcrumb)`
  font-size: 16px;
  color: #6c729d;
`;

export const StyledBreadcrumbItem = styled(Breadcrumb.Item)`
  font-size: 18px;
  & > span {
    cursor: pointer;
    color: #6c729d;
    text-decoration: underline;
  }
  &:last-child > span {
    cursor: default;
    text-decoration: none;
  }
`;
