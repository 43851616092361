import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DesktopOutlined, FileOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Layout, Menu, Space } from 'antd';
import Dashboard from '../../views/Dashboard';
import Orders from '../../views/Orders';
import Tasks from '../../views/Tasks';
import IProperty from '../../views/IProperty';
import FileComponent from '../../views/File/File';
import { UserInfo, UserEmail, StyledMenuItem } from './SideBar.styled';
import jsonData from '../../testJson/testfile.json';
import Business from '../../views/Business';
import Financial from '../../views/Financial';
import { useLocation, useNavigate } from 'react-router-dom';
import { setDecisionFlowStep } from '../../store/ducks/InstructionsWizard/operations';
import { AppDispatch } from '../../store/configureStore';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg';
import { ReactComponent as DashboardIconSelected } from '../../assets/icons/dashSelected.svg';
import { ReactComponent as DarkModeDashboardIcon } from '../../assets/icons/darkModeDashboard.svg';
import { ReactComponent as BusinessIcon } from '../../assets/icons/business.svg';
import { ReactComponent as BusinessIconSelected } from '../../assets/icons/businessSelected.svg';
import { ReactComponent as FilesIcon } from '../../assets/icons/files.svg';
import { ReactComponent as FilesIconSelected } from '../../assets/icons/filesSelected.svg';
import { ReactComponent as FinancialIcon } from '../../assets/icons/financial.svg';
import { ReactComponent as FinancialIconSelected } from '../../assets/icons/financialSelected.svg';
import { ReactComponent as IpIcon } from '../../assets/icons/ip.svg';
import { ReactComponent as IpIconSelected } from '../../assets/icons/ipSelected.svg';
import { ReactComponent as OrdersIcon } from '../../assets/icons/orders.svg';
import { ReactComponent as OrdersIconSelected } from '../../assets/icons/ordersSelected.svg';
import { ReactComponent as TasksIcon } from '../../assets/icons/tasks.svg';
import { ReactComponent as TasksIconSelected } from '../../assets/icons/tasksSelected.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as DarkModeBusiness } from '../../assets/icons/businessdark.svg';
import { ReactComponent as DarkIp } from '../../assets/icons/ipdark.svg';
import { ReactComponent as DarkModeFiles } from '../../assets/icons/filesdark.svg';
import { ReactComponent as DarkModeOrderd } from '../../assets/icons/ordersdark.svg';
import { ReactComponent as DarkModeTasks } from '../../assets/icons/tasksdark.svg';
import { ReactComponent as DarkModeFinancial } from '../../assets/icons/financialdark.svg';
import { ReactComponent as DashSelectedDarkMode } from '../../assets/icons/dashSelectedDarkMode.svg';
import { ReactComponent as FilesSelectedDarkMode } from '../../assets/icons/filesSelectedDarkMode.svg';
import { ReactComponent as FinancialSelectedDarkMode } from '../../assets/icons/financialSelectedDarkMode.svg';
import { ReactComponent as IpSelectedDatkMode } from '../../assets/icons/ipSelectedDatkMode.svg';

const { Content, Sider } = Layout;

interface SideBarProps {
  collapsed: boolean;
  isDarkMode: boolean;
  handleMenuItemClick: (
    parentLabel: string,
    label: string,
    count?: number
  ) => void;
}

interface MenuItem {
  key: string;
  icon: JSX.Element;
  label: string;
  path: string;
  component: JSX.Element;
  count: any;
  items: [];
}

const SideBar: React.FC<SideBarProps> = ({ collapsed, isDarkMode }) => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const sidebarStyles = {
    background: isDarkMode ? '#313349' : 'white',
    width: '134px',
    transition: 'background 0.3s, color 0.3s',
  };

  let data: any;

  const savedData = localStorage.getItem('selectedPropertyData');
  if (savedData) {
    data = JSON.parse(savedData);
  }

  const menuItems = [
    {
      key: 'dashboard',
      defaultIcon: <DashboardIcon />,
      selectedIcon: <DashboardIconSelected />,
      darkThemeIcon: <DarkModeDashboardIcon />,
      darkThemeIconSelected: <DashSelectedDarkMode />,
      label: 'Dashboard',
      path: '/dashboard',
      component: <Dashboard />,
      count: undefined,
    },
    {
      key: 'ip',
      defaultIcon: <IpIcon />,
      selectedIcon: <IpIconSelected />,
      darkThemeIcon: <DarkIp />,
      darkThemeIconSelected: <IpSelectedDatkMode />,
      label: 'IP',
      path: '/ip',
      component: <IProperty />,
      count: jsonData.data.iProperty.length,
    },
    // {
    //   key: 'business',
    //   defaultIcon: <BusinessIcon />,
    //   selectedIcon: <BusinessIconSelected />,
    //   darkThemeIcon: <DarkModeBusiness />,
    //   label: 'Business',
    //   path: '/business',
    //   component: <Business />,
    //   count: jsonData.data.business.length,
    // },
    {
      key: 'financial',
      defaultIcon: <FinancialIcon />,
      selectedIcon: <FinancialIconSelected />,
      darkThemeIcon: <DarkModeFinancial />,
      darkThemeIconSelected: <FinancialSelectedDarkMode />,
      label: 'Financial',
      path: '/financial',
      component: <Financial />,
      count: undefined,
    },
    // {
    //   key: 'tasks',
    //   defaultIcon: <TasksIcon />,
    //   selectedIcon: <TasksIconSelected />,
    //   darkThemeIcon: <DarkModeTasks />,
    //   label: 'Tasks',
    //   path: '/tasks',
    //   component: <Tasks />,
    //   count: 1,
    // },
    // {
    //   key: 'orders',
    //   defaultIcon: <OrdersIcon />,
    //   selectedIcon: <OrdersIconSelected />,
    //   darkThemeIcon: <DarkModeOrderd />,
    //   label: 'Orders',
    //   path: '/orders',
    //   component: <Orders />,
    //   count: undefined,
    // },
    {
      key: 'files',
      defaultIcon: <FilesIcon />,
      selectedIcon: <FilesIconSelected />,
      darkThemeIcon: <DarkModeFiles />,
      darkThemeIconSelected: <FilesSelectedDarkMode />,
      label: 'Files',
      path: '/files',
      component: <FileComponent />,
      count: jsonData.data.files.length,
    },
  ];

  const getSelectedKeys = () => {
    const path = location.pathname;
    if (path.includes('/files')) {
      return ['files'];
    } else if (path.includes('/financial')) {
      return ['financial'];
    } else if (path.includes('/ip')) {
      return ['ip'];
    } else if (path.includes('/tasks')) {
      return ['tasks'];
    } else if (path.includes('/wizard')) {
      return ['tasks'];
    } else if (path.includes('/dashboard')) {
      return ['dashboard'];
    } else if (path.includes('/business')) {
      return ['business'];
    }
    return [];
  };

  return (
    <Sider
      collapsible
      trigger={null}
      collapsed={collapsed}
      width='134px'
      style={sidebarStyles}
    >
      <Menu
        selectedKeys={getSelectedKeys()}
        style={{ backgroundColor: 'transparent', marginTop: '165px' }}
      >
        {/* <StyledMenuItem key='user-info' isDarkMode={isDarkMode} disabled /> */}
        {menuItems.map((item) => (
          <StyledMenuItem
            key={item.key}
            icon={
              location.pathname === item.path && !isDarkMode
                ? item.selectedIcon
                : location.pathname === item.path && isDarkMode
                ? item.darkThemeIconSelected || item.selectedIcon
                : location.pathname.includes('ip') &&
                  item.key === 'ip' &&
                  data &&
                  isDarkMode
                ? item.darkThemeIconSelected
                : item.darkThemeIcon
            }
            className={
              location.pathname == item.path ? 'ant-menu-item-selected' : ''
            }
            onClick={() => {
              navigate(item.path);
              dispatch(setDecisionFlowStep(0));
            }}
            collapsed={collapsed}
            isDarkMode={isDarkMode}
          >
            {item.label}
          </StyledMenuItem>
        ))}
      </Menu>
    </Sider>
  );
};

export default SideBar;
