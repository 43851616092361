import { environment } from '../../environments/environment';
import { CompanyDetails } from '../../store/ducks/Global/types';
import authHeader from '../authenticationService/auth-header';

export const updateCompanyAddress = async (
  companyAddressData: CompanyDetails
) => {
  try {
    const { userId } = JSON.parse(localStorage.getItem('loginInfo') || '{}');
    const { companyName, ...restData } = companyAddressData;
    const updatedCompanyAddressData = {
      ...restData,
      userId,
      name: companyName,
    };
    const response = await fetch(`${environment.companies}`, {
      method: 'PUT',
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedCompanyAddressData),
    });

    if (!response.ok) {
      throw new Error('Failed to update company address. Please try again.');
    }

    return 'Updated successfully';
  } catch (error: any) {
    throw new Error('Error updating company address: ' + error.message);
  }
};
