import React, { useEffect, useState } from 'react';
import { Button, Breadcrumb, Steps, theme, Modal, Tooltip } from 'antd';
import {
  RightCircleOutlined,
  CloseCircleOutlined,
  LeftCircleOutlined,
} from '@ant-design/icons';
import {
  StyledBreadcrumb,
  StyledBreadcrumbItem,
  StyledDocItem,
  StyledDocItemAdditInfo,
  TotalAmountWrapper,
} from './InstructionWizard.styled';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import {
  setDecisionFlowStep,
  setDocumentsData,
} from '../../store/ducks/InstructionsWizard/operations';
import { Divider, Tag } from 'antd';
import InvoicingStep from '../InvoicingStep';
import FinalizeOrder from '../FinalizeOrder';
import authHeader from '../../services/authenticationService/auth-header';
import { useNavigate } from 'react-router-dom';
import type { PaginationProps } from 'antd';
import { Spin, Pagination } from 'antd';
import { setPageInfo } from '../../store/ducks/Global/operations';
import { fetchTasksData } from '../../services/tasksDataService/tasks.service';

const InstructionWizard: React.FC = () => {
  const navigate = useNavigate();
  const { documents } = useSelector((state: RootState) => state.instructions);
  const [documentsData, setDocuments] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [taxableAmount, setTaxableAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [fileDownloaded, setFileDownloaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [current, setCurrent] = useState(0);

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page);
  };

  const onStepChange = (next: any) => {
    if (
      (next === current + 1 || next === current - 1) &&
      ((steps[current].title !== 'Confirmation' && totalAmount !== 0) ||
        selectedDecisions.length === documents.length)
    ) {
      setCurrent(next);
    }
  };

  const {
    userData: { publisherId, email },
  } = useSelector((state: RootState) => state.global);

  const handleCheckboxChange = (checked: boolean) => {
    setIsChecked(checked);
  };

  const [selectedDecisions, setSelectedDecisions] = useState(() => {
    const storedSelectedDecisions =
      JSON.parse(localStorage.getItem('selectedDecisions')!) || [];
    return storedSelectedDecisions;
  });

  const { token } = theme.useToken();
  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    width: '100%',
    marginTop: '30px',
    minHeight: '500px',
  };

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const data = await fetchTasksData();

        setDocuments(data);
        dispatch(setDocumentsData(data));
      } catch (error: any) {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
      }
    };

    fetchDocuments();
  }, [dispatch]);

  useEffect(() => {
    const storedSelectedDecisions =
      JSON.parse(localStorage.getItem('selectedDecisions')!) || [];

    const handleBeforeUnload = () => {
      localStorage.setItem(
        'selectedDecisions',
        JSON.stringify(selectedDecisions)
      );
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const cancel = () => {
    dispatch(setDecisionFlowStep(0));
    navigate('/tasks');
    localStorage.removeItem('selectedDecisions');
  };

  const handleBreadcrumbSelect = (documentId: any, selectedOption: string) => {
    setSelectedDecisions((prevSelectedDecisions: any) => {
      let updatedDecisions: { documentId: string; selectedOption: string }[] = [
        ...prevSelectedDecisions,
      ];

      const existingDocument = updatedDecisions.find(
        (doc: any) => doc.documentId === documentId
      ) as { documentId: string; selectedOption: string } | undefined;

      if (existingDocument) {
        existingDocument.selectedOption = selectedOption;
      } else {
        updatedDecisions.push({ documentId, selectedOption });
      }

      return updatedDecisions;
    });
  };

  const calculateTotalAmount = () => {
    let sum = 0;
    for (const decision of selectedDecisions) {
      const document = documents.find(
        (doc) => doc.taskGuid === decision.documentId
      );
      if (decision.selectedOption === 'Pay' && document) {
        sum += document.amount;
      }
    }
    return sum.toFixed(2);
  };

  useEffect(() => {
    let calculatedTotalAmount = 0;
    let totalTaxableAmount = 0;
    for (const docItem of documents) {
      const selectedOption = selectedDecisions.find(
        (decision: { documentId: string }) =>
          decision.documentId === docItem.taskGuid
      )?.selectedOption;

      if (selectedOption === 'Pay') {
        calculatedTotalAmount += docItem.amount;
        totalTaxableAmount += docItem.taxableAmount;
      }
    }

    setTotalAmount(calculatedTotalAmount);
    setTaxableAmount(totalTaxableAmount);
  }, [documents, selectedDecisions]);

  const taskElements = (isSorted: boolean) => {
    let sortedTest = [...documents];

    const nonPostponeItems = sortedTest.filter((item) => {
      const selectedOption = selectedDecisions.find(
        (decision: { documentId: string }) =>
          decision.documentId === item.taskGuid
      )?.selectedOption;
      return selectedOption !== 'Postpone';
    });

    if (isSorted) {
      sortedTest = nonPostponeItems.sort((a, b) => {
        const selectedOptionA = selectedDecisions.find(
          (decision: { documentId: string }) =>
            decision.documentId === a.taskGuid
        )?.selectedOption;
        const selectedOptionB = selectedDecisions.find(
          (decision: { documentId: string }) =>
            decision.documentId === b.taskGuid
        )?.selectedOption;

        const optionsOrder = ['Pay', 'Skip', 'Abandon'];

        return (
          optionsOrder.indexOf(selectedOptionA) -
          optionsOrder.indexOf(selectedOptionB)
        );
      });
    }

    let prevSelectedOption = '';

    const startIndex = (currentPage - 1) * 10;
    const spliceDocument = sortedTest.slice(startIndex, startIndex + 10);
    const data = spliceDocument.map((docItem: any, index: number) => {
      const {
        type,
        title,
        jurisdiction,
        applicationNumber,
        owner,
        webLink,
        priority,
        filing,
        granted,
        expiry,
        annuityYear,
        currency,
        amount,
        taskDeadline,
        guid,
        taskGuid,
      } = docItem;

      const selectedOption =
        selectedDecisions.find(
          (decision: { documentId: any }) => decision.documentId === taskGuid
        )?.selectedOption || '';

      const toDate = (timestamp: number) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
      };

      let dividerTitle = '';
      if (isSorted && selectedOption !== prevSelectedOption) {
        switch (selectedOption) {
          case 'Pay':
            dividerTitle = 'Pay';
            break;
          case 'Skip':
            dividerTitle = 'Skip';
            break;
          case 'Abandon':
            dividerTitle = 'Abandon';
            break;
          default:
            dividerTitle = '';
            break;
        }
        prevSelectedOption = selectedOption;
      }

      return (
        <div
          key={taskGuid}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {dividerTitle && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h3 style={{ margin: '10px 0', alignSelf: 'flex-start' }}>
                  {dividerTitle}
                </h3>
                {selectedOption === 'Pay' && isSorted && (
                  <TotalAmountWrapper>
                    Total:{' '}
                    {[currency].join(' • ') +
                      ' ' +
                      Number(totalAmount).toFixed(2)}
                  </TotalAmountWrapper>
                )}
              </div>
              <Divider style={{ margin: '10px 0', borderColor: '#f0f0f0' }} />
            </>
          )}
          {Object.keys(docItem).length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '30px',
              }}
            >
              <StyledDocItem key={taskGuid}>
                <b style={{ color: '#24466a' }}>{title}</b>
                <p style={{ color: '#24466a' }}>
                  {type +
                    ' ' +
                    jurisdiction +
                    ' ' +
                    [applicationNumber, owner].join(' • ')}
                </p>
                <p style={{ color: 'gray' }}>
                  {[
                    `priority ${toDate(priority)}`,
                    `filed ${toDate(filing)}`,
                    `granted ${toDate(granted)}`,
                    `expires ${toDate(expiry)}`,
                  ].join(' • ')}
                </p>
                <p style={{ color: 'gray' }}>
                  {'External Link: '}
                  <a href={webLink} target='_blank'>
                    Espacenet
                  </a>
                </p>
              </StyledDocItem>
              {isSorted && selectedOption === 'Pay' && (
                <Tag
                  color='green'
                  style={{
                    textAlign: 'end',
                    color: '#24466a',
                    marginRight: '50px',
                    alignSelf: 'center',
                  }}
                >
                  {[currency].join(' • ') + ' ' + Number(amount).toFixed(2)}
                </Tag>
              )}
              {!isSorted && (
                <StyledDocItemAdditInfo>
                  <p>
                    {[annuityYear, currency].join(' • ') +
                      ' ' +
                      Number(amount).toFixed(2)}
                  </p>
                  <p>{toDate(taskDeadline)}</p>
                </StyledDocItemAdditInfo>
              )}

              {!isSorted && (
                <div
                  style={{
                    textAlign: 'end',
                    color: '#24466a',
                    alignSelf: 'center',
                  }}
                >
                  <StyledBreadcrumb separator=''>
                    <StyledBreadcrumbItem>
                      <Button
                        type={selectedOption === 'Pay' ? 'primary' : 'default'}
                        onClick={() => handleBreadcrumbSelect(taskGuid, 'Pay')}
                      >
                        Pay
                      </Button>
                    </StyledBreadcrumbItem>
                    <StyledBreadcrumbItem>
                      <Button
                        type={
                          selectedOption === 'Abandon' ? 'primary' : 'default'
                        }
                        onClick={() =>
                          handleBreadcrumbSelect(docItem.taskGuid, 'Abandon')
                        }
                      >
                        Abandon
                      </Button>
                    </StyledBreadcrumbItem>
                    <StyledBreadcrumbItem>
                      <Button
                        type={selectedOption === 'Skip' ? 'primary' : 'default'}
                        onClick={() =>
                          handleBreadcrumbSelect(docItem.taskGuid, 'Skip')
                        }
                      >
                        Skip
                      </Button>
                    </StyledBreadcrumbItem>
                    <StyledBreadcrumbItem>
                      <Button
                        type={
                          selectedOption === 'Postpone' ? 'primary' : 'default'
                        }
                        onClick={() =>
                          handleBreadcrumbSelect(docItem.taskGuid, 'Postpone')
                        }
                      >
                        Postpone Decision
                      </Button>
                    </StyledBreadcrumbItem>
                  </StyledBreadcrumb>
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
    return (
      <>
        {data}
        {sortedTest.length > 10 ? (
          <Pagination
            defaultCurrent={1}
            total={sortedTest.length}
            onChange={onChange}
            showSizeChanger={false}
          />
        ) : (
          ''
        )}
      </>
    );
  };

  const steps = [
    {
      title: 'Decisions',
      content: taskElements(false),
    },
    {
      title: 'Confirmation',
      content: taskElements(true),
    },
    {
      title: 'Invoicing',
      content: (
        <InvoicingStep
          totalAmount={totalAmount}
          taxableAmount={taxableAmount}
        />
      ),
    },
    {
      title: 'Finalize',
      content: <FinalizeOrder setCheckboxState={handleCheckboxChange} />,
    },
  ];

  const handleFinalization = async () => {
    if (isChecked) {
      setLoading(true);
      setModalVisible(true);

      const userData = localStorage.getItem('loginInfo');
      let userId = '';
      if (userData) userId = JSON.parse(userData)?.userId;
      try {
        const requestBody = {
          userId: userId,
          publisherId: publisherId,
          email: email,
          totalAmount: totalAmount,
          taxableAmount: taxableAmount,
        };

        const response = await fetch(
          'https://api.ipmanagement.io/invoice/download',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              ...authHeader(),
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response) {
          const invoiceDownloadLink = await response.blob();
          setDownloadLink(window.URL.createObjectURL(invoiceDownloadLink));
          setModalVisible(true);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error('Failed to fetch');
        }
      } catch (error) {
        setLoading(false);
        console.error('Error during fetch:', error);
      }
    } else {
      console.log('Please accept the terms and conditions.');
    }
  };

  const handleModalOk = () => {
    setModalVisible(false);
    dispatch(setDecisionFlowStep(0));
    navigate('/tasks');
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  useEffect(() => {
    localStorage.setItem(
      'selectedDecisions',
      JSON.stringify(selectedDecisions)
    );
  }, [selectedDecisions]);

  const downloadFile = (url: RequestInfo | URL, filename: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  const handleDownloadClick = async () => {
    if (isChecked && downloadLink) {
      await downloadFile(downloadLink, 'invoice.pdf');
      setFileDownloaded(true);
    } else {
      console.log(
        'Please accept the terms and conditions and fetch the download link.'
      );
    }
  };
  console.log('lalala', selectedDecisions, documents);

  return (
    <div
      style={{
        margin: '30px 50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '30px',
      }}
    >
      <div
        style={{
          position: 'sticky',
          top: 0,
          background: 'white',
          zIndex: 1,
          padding: '20px',
        }}
      >
        <Steps current={current} onChange={onStepChange} items={items} />
      </div>
      <div style={contentStyle}>{steps[current].content}</div>
      <Modal
        title='Invoice Download'
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={() => setModalVisible(false)}
        okButtonProps={{ disabled: !fileDownloaded }}
      >
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <Spin size='large' />
            <p style={{ marginTop: '10px' }}>
              Generating the link to download the file...
            </p>
          </div>
        ) : (
          <p>
            Click here to{' '}
            <a
              href={downloadLink}
              onClick={handleDownloadClick}
              download='invoice.pdf'
            >
              download your Invoice.
            </a>
          </p>
        )}
      </Modal>
      <div style={{ alignSelf: 'flex-end' }}>
        {current > 0 && (
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => prev()}
            icon={<LeftCircleOutlined />}
          >
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Tooltip
            title={
              steps[current].title === 'Confirmation' &&
              totalAmount === 0 &&
              'To proceed, you must have selected at least one document for payment.'
            }
            color='gray'
          >
            <span>
              <Button
                type='primary'
                icon={<RightCircleOutlined />}
                onClick={() => next()}
                disabled={
                  (steps[current].title === 'Confirmation' &&
                    totalAmount === 0) ||
                  selectedDecisions.length < documents.length
                }
              >
                Proceed
              </Button>
            </span>
          </Tooltip>
        )}
        <Button
          type='primary'
          style={{ marginLeft: '10px', width: '107.67px', marginRight: '10px' }}
          icon={<CloseCircleOutlined />}
          onClick={() => cancel()}
        >
          Cancel
        </Button>
        {steps[current].title === 'Finalize' && (
          <Button
            style={{ width: '107.67px' }}
            disabled={!isChecked}
            type='primary'
            onClick={handleFinalization}
          >
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default InstructionWizard;
