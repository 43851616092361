import { Dispatch } from '@reduxjs/toolkit';
import instructionsWizardSlice from './instructionsWizardSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IDocument } from './types';

const { set_decision_flow_step, set_documents_data, set_loading, set_error } =
  instructionsWizardSlice.actions;

export const setDecisionFlowStep = createAsyncThunk(
  'setDecisionFlowStep',
  async (data: number, { dispatch }) => {
    try {
      dispatch(set_loading());
      dispatch(set_decision_flow_step(data));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export const setDocumentsData = createAsyncThunk(
  'setDocumentsData',
  async (data: any, { dispatch }) => {
    try {
      dispatch(set_loading());
      const documentsData = data;

      dispatch(set_documents_data(documentsData));
    } catch (error: any) {
      if (error.message) {
        dispatch(set_error(error.message));
      }
    }
  }
);

export default {
  setDecisionFlowStep,
  setDocumentsData,
};
