import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../store/configureStore';
import { StyledBreadcrumbItem, StyledBreadcrumb } from './TitleSection.styled';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrowLeft.svg';

type LabelMappings = {
  [key: string]: string;
};

const labelMappings: LabelMappings = {
  tasks: 'Tasks',
  wizard: 'Instructions Wizard',
  ip: 'IP',
};

interface BreadcrumbItem {
  key: string;
  title: React.ReactNode;
  onClick?: () => void;
}

const GenerateBreadcrumbItems = (
  onBreadcrumbClick: () => void,
  isDarkMode: boolean
) => {
  const location = useLocation();
  let paths = location.pathname.split('/').filter((p) => p);
  const breadcrumbs: BreadcrumbItem[] = [];

  for (let i = 0; i < paths.length; i++) {
    const segment = paths[i];
    const label = segment.charAt(0).toUpperCase() + segment.slice(1);
    const isLast = i === paths.length - 1;
    const pathToSegment = `/${paths.slice(0, i + 1).join('/')}`;

    // Check if the segment is after '/ip/' and if it's not the last segment
    if (i > 0 && paths[i - 1] === 'ip' && paths[i] !== 'insights') {
      const selectedItem = localStorage.getItem('selectedPropertyData');
      const parseData = selectedItem ? JSON.parse(selectedItem) : null;

      breadcrumbs.push({
        key: 'cards',
        title: (
          <span
            onClick={() => onBreadcrumbClick()}
            style={{
              color: isDarkMode ? '#FFFFFF1A' : '#DADADA',
              cursor: 'pointer',
            }}
          >
            Cards
          </span>
        ),
      });
      breadcrumbs.push({
        key: 'cards-details',
        title: (
          <span style={{ color: '#6C729D' }}>{parseData?.card?.subtitle}</span>
        ),
      });
      continue;
    } else if (paths[i] === 'insights') {
      breadcrumbs.push({
        key: 'cards',
        title: (
          <span
            onClick={() => onBreadcrumbClick()}
            style={{
              color: isDarkMode ? '#FFFFFF1A' : '#DADADA',
              cursor: 'pointer',
            }}
          >
            Cards
          </span>
        ),
      });
      breadcrumbs.push({
        key: 'insights',
        title: <span style={{ color: '#6C729D' }}>Insights</span>,
      });
      continue;
    }

    // if (paths[i] !== 'ip') {
    //   breadcrumbs.push({
    //     key: segment,
    //     title: isLast ? (
    //       <span style={{ color: '#6C729D' }}>{label}</span>
    //     ) : (
    //       <span onClick={() => onBreadcrumbClick(pathToSegment)}>{label}</span>
    //     ),
    //   });
    // }
  }

  return breadcrumbs;
};

const BreadcrumbComponent = ({ onBreadcrumbClick, isDarkMode }: any) => {
  return (
    <StyledBreadcrumb
      separator={<ArrowLeft />}
      style={{ fontSize: 20, fontWeight: 500 }}
      items={GenerateBreadcrumbItems(onBreadcrumbClick, isDarkMode)}
    ></StyledBreadcrumb>
  );
};

const TitleSection = ({ onBreadcrumbClick, isDarkMode }: any) => {
  const { currentPageName } = useSelector((state: RootState) => state.global);
  return (
    <div>
      <span
        style={{
          fontSize: '90px',
          color: '#6C729D',
          fontFamily: 'Futura',
        }}
      >
        {currentPageName}
      </span>
      <BreadcrumbComponent
        onBreadcrumbClick={onBreadcrumbClick}
        isDarkMode={isDarkMode}
      />
    </div>
  );
};

export default TitleSection;
