import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialFileState } from './initialState';
import { File, FileState } from './types';

const fileSlice = createSlice({
  name: 'File',
  initialState: initialFileState(),
  reducers: {
    search_file: (state: FileState, action: PayloadAction<string>) => {
      const searchTerm = action.payload.toLowerCase();
      state.searchResults.results = state.data.filter((item) => {
        const searchableFields = [item.filepath];

        return searchableFields.some(
          (field) => field && field.toLowerCase().includes(searchTerm)
        );
      });
    },
    set_file_data: (state: FileState, action: PayloadAction<File[]>) => {
      state.data = [...action.payload];
      state.searchResults.results = [];
    },
    set_selected_file: (state: FileState, action: PayloadAction<File>) => {
      state.selectedPropertyData = action.payload;
    },
    set_loading: (state, action) => {
      state.loading = action.payload;
    },
    set_error: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default fileSlice;
