export interface ShortCutType {
  filegroup: string;
  label: string;
  tag: string | string[];
}

export const shortcuts: ShortCutType[] = [
  {
    filegroup: 'IP portfolio active',
    label: 'IP rights',
    tag: 'IP rights',
  },
  {
    filegroup: 'IP portfolio active',
    label: 'Legal challenges',
    tag: 'Legal challenges',
  },
  {
    filegroup: 'IP portfolio active',
    label: 'Pending applications',
    tag: 'Pending applications',
  },
  {
    filegroup: 'IP portfolio active',
    label: 'Agreements',
    tag: 'Agreements',
  },
  {
    filegroup: 'IP portfolio active',
    label: 'Expired / closed IP rights and applications',
    tag: 'Expired / closed IP rights and applications',
  },
  {
    filegroup: 'IP portfolio active',
    label: 'Closed legal challenges',
    tag: 'Closed legal challenges',
  },
  {
    filegroup: 'IP portfolio active',
    label: 'Other',
    tag: 'Other',
  },
];
