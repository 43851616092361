import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

const currentUrl = window.location.hostname;
const isArnoldSiedsma = currentUrl.includes('arnold-siedsma');
const clientId = process.env.REACT_APP_SSO_AZURE_CLIENT_ID ?? '';
const redirectUri = isArnoldSiedsma
  ? process.env.REACT_APP_SSO_AZURE_REDIRECT_URL_AS
  : process.env.REACT_APP_SSO_AZURE_REDIRECT_URL;
const azureAdAuthority = process.env.REACT_APP_SSO_AZURE_AD_AUTHORITY;

export const initiateAzureADAuthentication = () => {
  try {
    const responseType = 'code';
    const scopes = ['openid', 'profile', 'email'];
    let authUrl = `${azureAdAuthority}`;
    console.log('redirectUri', redirectUri);
    authUrl += `?client_id=${encodeURIComponent(clientId)}`;
    authUrl += `&response_type=${encodeURIComponent(responseType)}`;
    authUrl += `&redirect_uri=${encodeURIComponent(redirectUri || '')}`;
    authUrl += `&scope=${encodeURIComponent(scopes.join(' '))}`;

    window.location.href = authUrl;
  } catch (error: any) {
    notification.error({
      message: 'Azure Sign In failed',
      description: error.message || 'An error occurred during Azure Sign In.',
    });
  }
};

export const processAzureADResponse = async (codeParam: string) => {
  try {
    const loginInfo = await loginWithCode(codeParam);
    return loginInfo;
  } catch (error: any) {
    console.log('error in processAzureADResponse catch:', error);
    throw error;
  }
};

export const loginWithCode = async (code: string) => {
  try {
    const response = await fetch(
      `https://auth.ipmanagement.io/auth/azure-login-callback?code=${code}`
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.log('response error', errorData, errorData.error_message);
      throw new Error(errorData.error_message);
    }

    const userData = await response.json();
    const loginInfo = {
      userId: userData.userId,
      accessToken: userData.accessToken,
    };
    localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
    return userData;
  } catch (error: any) {
    console.log('error in catch:', error);
    throw error;
  }
};
