import { ISearchState, IPropertyState } from './types';

const getInitialItemsPerPage = () => {
  const storedItemsPerPage = localStorage.getItem('itemsPerPage');
  return storedItemsPerPage ? parseInt(storedItemsPerPage, 10) : 24;
};

export const initialPropertyState = (): IPropertyState => ({
  cardsData: [],
  filteredData: [],
  searchResults: initialSearchState,
  searchTerm: '',
  currentPage: 1,
  itemsPerPage: getInitialItemsPerPage(),
  loading: false,
  error: null,
  selectedPropertyData: null,
  imageURLs: {}, // Add imageURLs here
});

export const initialSearchState: ISearchState = {
  results: [],
};
