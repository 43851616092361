import React, { useEffect, useState } from 'react';
import type { PaginationProps } from 'antd';
import { Button, notification, Pagination } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  setDecisionFlowStep,
  setDocumentsData,
} from '../../store/ducks/InstructionsWizard/operations';
import { AppDispatch } from '../../store/configureStore';
import { fetchTasksData } from '../../services/tasksDataService/tasks.service';
import { DocumentsWrapper } from './Tasks.styled';
import { setPageInfo } from '../../store/ducks/Global/operations';
import { useNavigate } from 'react-router-dom';

const Tasks: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [documentsData, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    dispatch(
      setPageInfo({
        currentPageName: 'Tasks',
        numberOfElem: 2,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const data = await fetchTasksData();

        setDocuments(data);
        dispatch(setDocumentsData(data));
      } catch (error: any) {
        const errorMessage =
          error.response?.data?.message || error.message || error.toString();
        notification.error({
          message: 'Request failed',
          description: errorMessage,
        });
      }
    };

    fetchDocuments();
  }, [dispatch]);

  const toDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const startIndex = (currentPage - 1) * 10;
  const spliceDocument = documentsData.slice(startIndex, startIndex + 10);

  const renderDocuments = spliceDocument.map((docItem) => {
    const {
      type,
      title,
      jurisdiction,
      applicationNumber,
      owner,
      webLink,
      priority,
      filing,
      granted,
      expiry,
      annuityYear,
      currency,
      amount,
      taskDeadline,
      //guid,
    } = docItem;

    return (
      Object.keys(docItem).length > 0 && (
        <div
          // key={guid}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '30px',
          }}
        >
          <div style={{ textAlign: 'start' }}>
            <b style={{ color: '#24466a' }}>{title}</b>
            <p style={{ color: '#24466a' }}>
              {type +
                ' ' +
                jurisdiction +
                ' ' +
                [applicationNumber, owner].join(' • ')}
            </p>
            <p style={{ color: 'gray' }}>
              {[
                `priority ${toDate(priority)}`,
                `filed ${toDate(filing)}`,
                `granted ${toDate(granted)}`,
                `expires ${toDate(expiry)}`,
              ].join(' • ')}
            </p>
            <p style={{ color: 'gray' }}>
              {'External Link: '}
              <a href={webLink} target='_blank' rel='noopener noreferrer'>
                Espacenet
              </a>
            </p>
          </div>
          <div style={{ textAlign: 'end', color: '#24466a' }}>
            <p>
              {[annuityYear, currency].join(' • ') +
                ' ' +
                Number(amount).toFixed(2)}
            </p>
            <p>{toDate(taskDeadline)}</p>
          </div>
        </div>
      )
    );
  });

  const handleStartDecision = (step: number) => {
    dispatch(setDecisionFlowStep(step));
    navigate('wizard');
  };

  return (
    <div
      style={{
        margin: '30px 50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '30px',
      }}
    >
      <div style={{ alignSelf: 'flex-end' }}>
        <Button
          type='primary'
          onClick={() => handleStartDecision(1)}
          icon={<RightCircleOutlined />}
          size='large'
        >
          Start Decision Flow
        </Button>
      </div>
      <DocumentsWrapper>{renderDocuments}</DocumentsWrapper>
      {documentsData.length > 10 ? (
        <Pagination
          defaultCurrent={1}
          total={documentsData.length}
          onChange={onChange}
          showSizeChanger={false}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default Tasks;
