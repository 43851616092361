import React from 'react';
import DataCard from './DataCard';
import { ReactComponent as NewTag } from '../assets/icons/newTag.svg';
import { Button } from 'antd';

interface GroupedCardsProps {
  groupedData: Record<string, any[]>;
  isDarkMode: boolean;
  isFiles?: boolean;
  onMarkAllAsRead?: () => void;
  onMarkAsRead?: (guid: string) => void; // Ensure onMarkAsRead takes `guid` to identify the file
}

const GroupedCards: React.FC<GroupedCardsProps> = ({
  groupedData,
  isDarkMode,
  isFiles,
  onMarkAllAsRead,
  onMarkAsRead,
}) => {
  const sortedGroupKeys = Object.keys(groupedData).sort((a, b) =>
    b.localeCompare(a)
  );

  const hasUnreadFiles = sortedGroupKeys.some((year) =>
    groupedData[year].some((item: any) => !item.isRead)
  );

  return (
    <div>
      {sortedGroupKeys.map((year) => (
        <div key={year} style={{ marginBottom: '15px', position: 'relative' }}>
          {!isFiles && (
            <div
              style={{
                color: '#656A92',
                border: '1px solid #656A92',
                height: '30px',
                width: '70px',
                borderRadius: '29px',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '15px',
              }}
            >
              {year}
            </div>
          )}
          {/* Render Unread Files First */}
          {groupedData[year]
            .filter((item) => !item.isRead)
            .map((item: any) => (
              <div
                key={item.guid}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: '10px',
                  // justifyContent: 'space-between',
                }}
              >
                {isFiles && !item.isRead && (
                  <div
                    style={{
                      width: '27px',
                      height: '27px',
                      position: 'relative',
                    }}
                  >
                    <NewTag />
                  </div>
                )}
                <DataCard
                  data={item}
                  isDarkMode={isDarkMode}
                  isFiles={isFiles}
                  onMarkAsRead={() => onMarkAsRead && onMarkAsRead(item.guid)}
                />
              </div>
            ))}

          {/* "Mark All as Read" Button for Unread Files */}
          {isFiles && hasUnreadFiles && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '15px',
                position: 'relative',
              }}
            >
              <Button
                ghost
                onClick={() => onMarkAllAsRead && onMarkAllAsRead()}
                style={{
                  color: '#6C729D',
                  border: 'none',
                  padding: 0,
                  fontSize: '16px',
                  fontFamily: 'Futura',
                }}
              >
                mark all as read
              </Button>
            </div>
          )}

          {/* Render Read Files */}
          {groupedData[year]
            .filter((item) => item.isRead)
            .map((item: any) => (
              <div
                key={item.guid}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: '10px',
                }}
              >
                <div
                  style={{
                    width: '27px',
                    height: '27px',
                  }}
                />
                <DataCard
                  data={item}
                  isDarkMode={isDarkMode}
                  isFiles={isFiles}
                  onMarkAsRead={onMarkAsRead}
                />
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default GroupedCards;
