import React, { useState, useEffect } from 'react';
import { Input, Space } from 'antd';
import { StyledSearch } from './Search.styled';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';

const { Search } = Input;

interface SearchComponentProps {
  onSearch: (value: string) => void; // Callback function to trigger search
  isDarkMode: boolean; // Dark mode flag
  searchTerm?: string; // Optional initial search term
  loading?: boolean;
}

const SearchComponent: React.FC<SearchComponentProps> = ({
  onSearch,
  isDarkMode,
  searchTerm,
  loading,
}: SearchComponentProps) => {
  const [searchTermInput, setSearchTermInput] = useState(searchTerm || '');

  // Sync local state with external prop when the prop changes
  useEffect(() => {
    setSearchTermInput(searchTerm || '');
  }, [searchTerm]);

  // Trigger the onSearch callback with the current input value
  const handleSearch = () => {
    onSearch(searchTermInput);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchTermInput(inputValue);

    // If the input is cleared, trigger onSearch with an empty value
    if (inputValue === '') {
      onSearch(''); // Notify parent that the search input is cleared
    }
  };

  return (
    <Space style={{ width: '327px', alignSelf: 'end' }}>
      <StyledSearch
        size='large'
        style={{ width: 327 }}
        placeholder='Search'
        allowClear
        value={searchTermInput}
        onPressEnter={handleSearch}
        onSearch={handleSearch}
        onChange={handleChange}
        suffix={
          <button
            onClick={handleSearch}
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
            }}
          >
            <SearchIcon />
          </button>
        }
        isDarkMode={isDarkMode}
        disabled={loading}
      />
    </Space>
  );
};

export default SearchComponent;
