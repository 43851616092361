import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  Button,
  Menu,
  Dropdown,
  theme,
  Layout,
  MenuProps,
  Modal,
  Radio,
} from 'antd';
import { StyledButton, StyledLayout } from '../Layout.styled';
import SideBar from '../../components/SideBar';
import { fetchUserData } from '../../services/userDataService/user.service';
import { RootState } from '../../store/configureStore';
import InstructionWizard from '../../views/InstructionWizard';
import { AppDispatch } from '../../store/configureStore';
import { setDecisionFlowStep } from '../../store/ducks/InstructionsWizard/operations';
import { setUserData, setUiTheme } from '../../store/ducks/Global/operations';
import Financial from '../../views/Financial';
import { ReactComponent as ThemeIcon } from '../../assets/icons/themeIcon.svg';
import { ReactComponent as NotificationIcon } from '../../assets/icons/notification.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as DropdownArrow } from '../../assets/icons/dropdownArrow.svg';
import {
  UserInfo,
  UserName,
  UserInfoContainer,
} from '../../components/SideBar/SideBar.styled';
import TitleSection from '../../components/TitleSection';
import {
  setFilteredIPCardsData,
  setIPCardsData,
  setItemsPerPage,
} from '../../store/ducks/IProperty/operations';

const { Header, Content } = Layout;

interface StyledProps extends MenuProps {
  collapsed?: boolean;
  isDarkMode?: boolean;
}

interface IOrderPortalLayoutProps {
  activeTab?: string;
  activeKey?: string;
}

const StyledModal = styled(Modal)`
  .ant-modal-title {
    text-align: center;
    font-weight: bold;
  }
`;

const CompanyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
`;

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .ant-radio-wrapper {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    transition: all 0.3s;
    text-align: center;

    &:hover {
      border-color: #1890ff;
    }

    &.ant-radio-wrapper-checked {
      background: #e6f7ff;
      border-color: #1890ff;
    }
  }
`;

interface Company {
  guid: string;
  companyId: string;
  companyName: string;
}

const OrderPortalLayout: React.FC<IOrderPortalLayoutProps> = ({}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [showSplash, setShowSplash] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { decisionFlowStep } = useSelector(
    (state: RootState) => state.instructions
  );

  const { userData, currentPageName, numberOfElem } = useSelector(
    (state: RootState) => state.global
  );

  const fullName = userData
    ? userData.firstName + ' ' + userData.lastName
    : 'No user data';

  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem('theme') === 'dark';
  });

  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);

  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [isMandatorySelection, setIsMandatorySelection] = useState(false);

  useEffect(() => {
    const storedCompanyId = localStorage.getItem('selectedCompany');

    // Sync selectedCompany with userData
    if (storedCompanyId && userData?.companies) {
      const company = userData.companies.find(
        (company) => company.guid === storedCompanyId
      );
      setSelectedCompany(company || null);
    }
  }, [userData]);

  useEffect(() => {
    const isLoggedIn = true; // Replace with actual login status check
    const companySelected = localStorage.getItem('companySelected');

    if (isLoggedIn && userData?.companies) {
      if (!companySelected && userData.companies.length > 1) {
        setIsMandatorySelection(true); // Require selection on first show
        setShowCompanyModal(true);
      } else if (userData.companies.length === 1) {
        setSelectedCompany(userData.companies[0]);
        localStorage.setItem('selectedCompany', userData.companies[0].guid);
        localStorage.setItem('companySelected', 'true');
      }
    }
  }, [userData]);

  const handleCompanySelect = () => {
    if (selectedCompany) {
      localStorage.setItem('selectedCompany', selectedCompany.guid);
      localStorage.setItem('companySelected', 'true');
      setShowCompanyModal(false);
    }
  };

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    dispatch(setUiTheme(localStorage.getItem('theme') || ''));
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    dispatch(setUiTheme(localStorage.getItem('theme') || ''));
  };

  const [selectedComponentInfo, setSelectedComponentInfo] = useState<{
    component?: React.ReactNode;
    parentLabel?: string;
    label?: string;
    count?: number;
  }>({
    count: numberOfElem,
  });

  useEffect(() => {
    setSelectedComponentInfo({
      component: <Financial />,
      parentLabel: currentPageName,
      label: '',
      count: numberOfElem,
    });
  }, [currentPageName, numberOfElem]);

  useEffect(() => {
    const modalShownBefore = localStorage.getItem('splashModalShown');

    if (!modalShownBefore) {
      setShowSplash(true);
      localStorage.setItem('splashModalShown', 'true');
    }

    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const memoizedFetchUserData = useMemo(() => fetchUserData, []);

  useEffect(() => {
    dispatch(setDecisionFlowStep(0));
    // dispatch(setSelectedPropertyData(null));
  }, [selectedComponentInfo]);

  useEffect(() => {
    const fetchUserDataAndDispatch = async () => {
      try {
        const fetchedUserData = await memoizedFetchUserData();
        dispatch(setUserData(fetchedUserData));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserDataAndDispatch();
  }, []);

  const handleMenuItemClick = (
    parentLabel: string,
    label: string,
    count?: number
  ) => {};

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setFilteredIPCardsData([]));
    dispatch(setIPCardsData([]));
    dispatch(setItemsPerPage(24));
    dispatch(setUserData({}));
    navigate('/login');
  };

  type LabelMappings = {
    [key: string]: string;
  };

  const labelMappings: LabelMappings = {
    tasks: 'Tasks',
    wizard: 'Instructions Wizard',
    ip: 'IP management',
  };

  const onBreadcrumbClick = () => {
    dispatch(setDecisionFlowStep(0));
    localStorage.removeItem('selectedPropertyData');
    navigate(-1);
  };

  const menu = (
    <Menu>
      <Menu.Item
        key='choose-company'
        onClick={() => {
          setIsMandatorySelection(false); // Allow voluntary closing
          setShowCompanyModal(true);
        }}
      >
        Choose Company
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='logout' onClick={handleLogout}>
        <LogoutOutlined /> Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh', display: 'flex' }}>
      <SideBar
        collapsed={collapsed}
        handleMenuItemClick={handleMenuItemClick}
        isDarkMode={isDarkMode}
      />
      <StyledLayout isDarkMode={isDarkMode}>
        <Header
          style={{
            padding: 0,
            paddingTop: 10,
            height: 'auto',
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '20px',
              height: 'inherit',
              marginRight: '20px',
            }}
          >
            <StyledButton
              icon={<ThemeIcon style={{ margin: 'auto' }} />}
              onClick={toggleTheme}
              style={{
                background: isDarkMode ? '#FFFFFF1A' : '#00A787',
              }}
            />
            <StyledButton
              icon={<NotificationIcon style={{ margin: 'auto' }} />}
              style={{
                transition: 'background 0.3s, color 0.3s',
                background: isDarkMode ? '#FFFFFF1A' : '#D9D9D9',
              }}
            />
            <StyledButton
              icon={<SearchIcon style={{ margin: 'auto' }} />}
              style={{
                background: isDarkMode ? '#FFFFFF1A' : '#D9D9D9',
              }}
            />
            <Dropdown
              overlay={menu}
              placement='bottomRight'
              trigger={['click']}
              overlayStyle={{ padding: 0 }}
            >
              <Button
                style={{
                  padding: 0,
                  border: 'none',
                  background: 'transparent',
                  boxShadow: 'none',
                }}
              >
                <UserInfoContainer>
                  <UserInfo>
                    <UserName isDarkMode={isDarkMode}>{fullName}</UserName>
                    <UserName style={{ fontSize: '11px' }}>
                      {selectedCompany?.companyName || 'No Company Selected'}
                    </UserName>
                  </UserInfo>

                  <DropdownArrow />
                </UserInfoContainer>
              </Button>
            </Dropdown>
            {/* </UserInfoContainer> */}
          </div>
        </Header>
        <Content
          style={{
            margin: '0 50px',
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            borderRadius: '10px',
          }}
        >
          <TitleSection
            onBreadcrumbClick={onBreadcrumbClick}
            isDarkMode={isDarkMode}
          />

          {selectedComponentInfo.parentLabel === 'Tasks' &&
          decisionFlowStep === 1 ? (
            <InstructionWizard />
          ) : (
            <Outlet />
          )}
        </Content>
      </StyledLayout>
      <StyledModal
        title='Select a Company'
        visible={showCompanyModal}
        onCancel={() => {
          if (!isMandatorySelection) {
            setShowCompanyModal(false);
          }
        }}
        closable={!isMandatorySelection} // Disable 'x' button during mandatory selection
        footer={
          <Button
            type='primary'
            disabled={!selectedCompany}
            onClick={() => {
              handleCompanySelect();
              setIsMandatorySelection(false); // Allow closing after selection
            }}
          >
            Confirm
          </Button>
        }
        maskClosable={!isMandatorySelection} // Prevent outside click to close modal
      >
        <CompanyList>
          <StyledRadioGroup
            onChange={(e) => {
              const company = userData?.companies?.find(
                (company) => company.guid === e.target.value
              );
              setSelectedCompany(company || null);
            }}
            value={selectedCompany?.guid || ''}
          >
            {userData?.companies?.map((company) => (
              <Radio key={company.guid} value={company.guid}>
                {company.companyName}
              </Radio>
            ))}
          </StyledRadioGroup>
        </CompanyList>
      </StyledModal>
    </Layout>
  );
};

export default OrderPortalLayout;
